import React from "react";
import { AlarmIndicator } from "../Shared/AlarmIndicator";
import SiteLink from "../Shared/SiteLink";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const MapPopup = ({ site }) => {
  return (
    <Box className="map-popover">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <SiteLink site={site} />
        {site.alarms ? (
          <Box ml="1em">
            <AlarmIndicator alarms={site.alarms} />
          </Box>
        ) : null}
      </Box>
      {site.power_data !== null ? (
        <Typography variant="caption">
          Current Production:{" "}
          {site?.power_data && site.power_data?.total_power !== null
            ? site.power_data?.total_power + " " + site.power_data?.unit
            : "--"}
        </Typography>
      ) : null}
      {site.status ? <Typography variant="caption">{site.status}</Typography> : null}
    </Box>
  );
};

MapPopup.propTypes = {
  site: PropTypes.object,
};

export default MapPopup;
