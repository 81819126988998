/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./style/theme";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "./components/Common/FallbackComponent";
import ScrollToTop from "./components/Shared/ScrollToTop";
import { LicenseInfo } from "@mui/x-license";
import Auth0ProviderWithNavigate from "./components/Auth/Auth0ProviderWithNavigate";

import "bootstrap/dist/css/bootstrap.min.css";
import "mapbox-gl/dist/mapbox-gl.css";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_PRO_LICENSE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));

const LDProvider = withLDProvider({
  clientSideID: `${process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID}`,
  context: {
    kind: "user",
    key: "defaultUser",
    name: "defaultUser",
    email: "defaultUser@scalemicrogrids.com",
  },
})(App);

root.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <ThemeProvider theme={theme}>
            <ScrollToTop />
            <LDProvider />
          </ThemeProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </QueryClientProvider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
