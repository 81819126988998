/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Divider, Breadcrumbs, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import SitesTable from "./SitesTable";
import UsersTable from "./UsersTable";
import SiteSettings from "./SiteSettings";
import UserSettings from "./UserSettings";
import { useNavigate } from "react-router-dom";
import OrganizationSettings from "./OrganizationSettings";
import { useQuery } from "@tanstack/react-query";
import { getOrganizationUsersListQuery } from "../../lib/Queries";

const OrganizationPage = () => {
  const theme = useTheme();
  const location = useLocation();
  const { organization } = location.state || {};
  const styles = {
    caption: {
      color: "rgba(0, 0, 0, 0.60)",
      marginBottom: "1rem",
    },
    settingsPanel: {
      width: "100%",
      py: "3rem",
      px: "4rem",
    },
    subtitle: {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "700",
    },
    genericTab: {
      backgroundColor: "#fff",
      border: "1px solid #E1E2E4",
      borderRadius: "5px",
      width: "12rem",
      height: "180px",
      justifyContent: "center",
      fontSize: 12,
    },
    genericTabLabel: {
      fontSize: 14,
    },
  };
  const organizationUuid = organization?.uuid;
  const [name, setName] = useState(organization?.name || null);
  const [tabIndex, setTabIndex] = useState(0); // State to manage the selected tab
  const [selectedSite, setSelectedSite] = useState(null); // State to manage the selected site
  const [selectedUser, setSelectedUser] = useState(null); // State to manage the selected user
  const {
    data: usersList,
    isLoading: usersListIsLoading,
    refetch: refetchUsersList,
  } = useQuery(getOrganizationUsersListQuery(organizationUuid));

  const navigate = useNavigate();
  const handleSiteRowClick = (site) => {
    setSelectedSite(site);
  };
  const handleUserRowClick = (user) => {
    setSelectedUser(user);
  };
  const handleUpdatedUser = (user) => {
    setSelectedUser(user);
    refetchUsersList();
  };
  const handleModifiedUsers = () => {
    refetchUsersList();
    setSelectedUser(null);
  };
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 0) {
      setSelectedSite(null);
      setSelectedUser(null);
    }
  };

  const handleTabClick = () => {
    setSelectedSite(null);
    setSelectedUser(null);
  };

  return (
    <Box sx={theme.classes.pageContainer}>
      <Box sx={theme.classes.page}>
        <Box>
          {/* TODO standardize this title bar into component so styles are not repeated */}
          <Typography sx={{ mb: "1.5rem", fontSize: "24px", fontWeight: 600 }} variant="h5">
            Admin
          </Typography>
          <Divider />
          <Breadcrumbs aria-label="breadcrumb" sx={{ ...styles.caption, mt: "32px" }}>
            <Button
              sx={{ fontSize: 16, textDecoration: "none", color: "inherit" }}
              onClick={() => navigate("/settings")}
            >
              Organizations
            </Button>
            <Button
              sx={{
                fontSize: 16,
                textDecoration: "none",
                color: selectedSite || selectedUser ? "inherit" : "text.primary",
              }}
              onClick={() => {
                setSelectedSite(null);
                setSelectedUser(null);
                if (organization?.uuid) {
                  navigate(`/organization/${organization.name.replace(/ /g, "-")}`, {
                    state: { organization },
                  });
                }
              }}
            >
              {name}
            </Button>
            {selectedSite && (
              <Button sx={{ fontSize: 16, textDecoration: "none", color: "text.primary" }}>
                {selectedSite.name}
              </Button>
            )}
            {selectedUser && (
              <Button sx={{ fontSize: 16, textDecoration: "none", color: "text.primary" }}>
                {selectedUser.name}
              </Button>
            )}
          </Breadcrumbs>
          <Box display="flex" flexDirection="row" mt="2rem" width="100%">
            <Box sx={{ mr: "2rem" }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="Organization Tabs"
                orientation="vertical"
                sx={styles.genericTab}
              >
                <Tab sx={{ ...styles.genericTabLabel, mt: "20px" }} label="Profile" />
                <Tab sx={styles.genericTabLabel} label="Sites" onClick={() => handleTabClick()} />
                <Tab sx={styles.genericTabLabel} label="Users" onClick={() => handleTabClick()} />
              </Tabs>
            </Box>
            <Box sx={{ flex: 1 }}>
              {tabIndex === 0 && <OrganizationSettings organization={organization} />}
              {tabIndex === 1 && (
                <Box>
                  {!selectedSite && (
                    <Box sx={theme.classes.genericPanel}>
                      <Typography variant="subtitle2" sx={styles.subtitle}>
                        Sites
                      </Typography>
                      <Box sx={styles.settingsPanel}>
                        <Box display="flex" flexDirection="column" width="100%">
                          <SitesTable
                            OrganizationUuid={organization?.uuid}
                            onRowClick={handleSiteRowClick}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {selectedSite && (
                    <SiteSettings OrganizationUuid={organizationUuid} site={selectedSite} />
                  )}
                </Box>
              )}
              {tabIndex === 2 && (
                <Box>
                  {!selectedUser && (
                    <Box sx={theme.classes.genericPanel}>
                      <Typography variant="subtitle2" sx={styles.subtitle}>
                        Users
                      </Typography>
                      <Box sx={styles.settingsPanel}>
                        <Box display="flex" flexDirection="column" width="100%">
                          <UsersTable
                            usersList={usersList}
                            onRowClick={handleUserRowClick}
                            onCreated={() => handleModifiedUsers()}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {selectedUser && (
                    <UserSettings
                      user={selectedUser}
                      onSave={handleUpdatedUser}
                      onDelete={() => handleModifiedUsers()}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

OrganizationPage.propTypes = {
  OrganizationPage: PropTypes.object,
};

export default OrganizationPage;
