/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import UserSettings from "./UserSettings";

const styles = {
  caption: {
    color: "rgba(0, 0, 0, 0.60)",
    marginBottom: "1rem",
  },
  settingsPanel: {
    width: "100%",
    py: "3rem",
    px: "4rem",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "700",
  },
  dialogContent: {
    maxWidth: "100%", // Ensure it doesn't exceed the viewport width
  },
};

const NewUserModal = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog maxWidth="936px" open={open} onClose={onClose}>
      <DialogContent>
        <Box sx={styles.settingsPanel}>
          <UserSettings user={null} onClose={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

NewUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewUserModal;
