/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const PieChartNode = (props) => {
  const pieChartColor = isNaN(props.value) ? "#E4E4E4" : "#85B953";
  const value = isNaN(props.value) ? "--" : `${Math.round(props.value)}%`;
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        top: "3px",
        zIndex: "999",
      }}
    >
      <Box
        sx={{
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          border: "1px solid #868686",
        }}
      >
        <CircularProgress
          variant="determinate"
          {...props}
          data-testid="progress-bar"
          style={{
            position: "absolute",
            width: "31px",
            height: "31px",
          }}
          sx={{
            color: pieChartColor,
          }}
          thickness={9}
        />
        <Box
          sx={{
            width: "19px",
            height: "19px",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            border: "1px solid #868686",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            style={{
              font: "--font-style-500",
              fontSize: "6px",
              color: "#868686",
              marginTop: "1px",
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

PieChartNode.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default PieChartNode;
