/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

interface Alarm {
  criticality: string;
  count: number;
  assembly_uuid: string;
  source?: string;
}

interface Assembly {
  uuid: string;
  name: string;
}

interface Site {
  state: string;
  alarms: Alarm[];
}

export const toISOStringWithTimezone = (date: Date | string, tzString: string): string => {
  return dayjs(date).tz(tzString, true).format();
};

export const decorateAlarms = (alarms: Alarm[], assemblies: Assembly[]): void => {
  alarms.forEach((alarm) => {
    let assembly = assemblies.find((assembly) => assembly.uuid === alarm.assembly_uuid);
    if (assembly) {
      alarm.source = assembly.name;
    }
  });
};

export const getMarkerColor = (site: Site): string => {
  const alarms = site.alarms;
  if (!alarms || alarms.length === 0) {
    return site.state === "commissioned" ? "#85B953" : "grey";
  }

  const hasHighCriticality = alarms.find((alarm) => alarm.criticality === "high")?.count;
  const hasMediumCriticality = alarms.find((alarm) => alarm.criticality === "medium")?.count;

  if (hasHighCriticality) {
    return "red";
  } else if (hasMediumCriticality) {
    return "#F3A944";
  } else {
    return "#85B953";
  }
};

export const getShortTimezone = (timeZone: string) => {
  const date = new Date();

  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone,
    timeZoneName: "short",
  });

  const parts = formatter.formatToParts(date);
  const timeZonePart = parts.find((part) => part.type === "timeZoneName");

  return timeZonePart ? timeZonePart.value : null;
};

export const convertTz = (date: Date | string, tzString: string): Date => {
  const inTz = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    }),
  );
  return inTz;
};

export const getAlarmFormattedTimeString = (date: string, timezone: string | null) => {
  if (timezone) {
    const dateTimezone = convertTz(date, timezone);
    const shortTimezoneString = getShortTimezone(timezone);
    const dateFormatted = dateTimezone.toLocaleTimeString("en-US", {
      hour12: false,
    });
    return dateFormatted + " " + shortTimezoneString;
  } else {
    return new Date(date).toLocaleTimeString("en-US", {
      hour12: false,
    });
  }
};

export const getAlarmFormattedDateString = (date: string, timezone?: string) => {
  if (timezone) {
    return convertTz(date, timezone).toLocaleDateString("en-US", {
      hour12: false,
    });
  } else {
    return new Date(date).toLocaleDateString("en-US", {
      hour12: false,
    });
  }
};

export const getCurrentTimeInTimezone = (timezone: string) => {
  const localDate = dayjs();
  const convertedDate = localDate.tz(timezone);
  const formattedDate = convertedDate.format("YYYY-MM-DDTHH:mm:ssZ");
  return formattedDate;
};
