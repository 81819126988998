/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

const AlarmCircle = ({ alarmSeverity, resolved }) => {
  const theme = useTheme();

  let alarmColor = theme.palette.error.main;
  if (alarmSeverity.toLowerCase() === "amber") {
    alarmColor = theme.palette.warning.main;
  }

  const output = (
    <>
      {!resolved ? (
        <Box sx={[{ background: alarmColor + "!important" }, theme.classes.point]}></Box>
      ) : (
        <Box sx={[{ borderColor: alarmColor + "!important" }, theme.classes.pointHollow]}></Box>
      )}
    </>
  );

  return output;
};

AlarmCircle.propTypes = {
  alarmSeverity: PropTypes.string.isRequired,
  resolved: PropTypes.bool,
};

export default AlarmCircle;
