import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceArea,
  ComposedChart,
  Tooltip,
  Line,
} from "recharts";
import GraphUtil from "../../../lib/GraphUtil";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AlarmTimelineTooltip from "./AlarmTimelineTooltip";
import AlarmsModal from "./AlarmsModal";

const AlarmTimeline = ({
  timeSeries,
  displayRange,
  bigBuckets,
  alarmHistoryData,
  position,
  axisOnly,
  getBucketAlarms,
  timezone,
  leftMargin,
  rightMargin,
}) => {
  const [currentBigBucketTime, setCurrentBigBucketTime] = useState(null);
  const [currentBigBucket, setCurrentBigBucket] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [payload, setPayload] = useState([]);
  const [selectedAlarms, setSelectedAlarms] = useState([]);

  const getBigBucketTime = (state) => state?.activePayload?.[0]?.payload?.bigBucket;

  const getSelectedBigBucket = (state, bigBuckets) => {
    const bigBucketTime = getBigBucketTime(state);
    const bigBucket = bigBuckets.find((bigBucket) => bigBucket.middle === bigBucketTime);
    return bigBucket;
  };

  const updateSelectedAlarms = (state) => {
    if (!state.activePayload?.length) return null;

    const selectedAlarms = getBucketAlarms(state.activePayload);
    setSelectedAlarms(selectedAlarms);
  };

  const getAlarmSpecificComponents = () => (
    <>
      <ReferenceArea y1={70} y2={100} shape={GraphUtil.getAlarmTimelineArea} />
      <YAxis axisLine={true} domain={[0, 100]} hide={true} />
      {GraphUtil.getAlarmTimelineDots(
        bigBuckets,
        alarmHistoryData,
        currentBigBucketTime,
        tooltipVisible,
      )}
    </>
  );

  return (
    <>
      <Box id="alarm-timeline" sx={{ width: "100%", height: "60px" }}>
        <ResponsiveContainer width="99%" height="99%">
          <ComposedChart
            data={timeSeries}
            onMouseMove={(state) => {
              const bigBucketTime = getBigBucketTime(state);
              setCurrentBigBucketTime(bigBucketTime);
              if (bigBucketTime) {
                setTooltipVisible(true);
              }
            }}
            onMouseOut={() => {
              setTooltipVisible(false);
            }}
            onClick={(state) => {
              const shouldOpen = state?.activePayload?.length > 0;
              if (!shouldOpen) return;

              updateSelectedAlarms(state);
              setPayload(state?.activePayload);
              setCurrentBigBucket(getSelectedBigBucket(state, bigBuckets));
              setModalOpen(true);
            }}
            margin={{
              left: leftMargin,
              right: rightMargin,
            }}
          >
            {!axisOnly && getAlarmSpecificComponents()}
            <Tooltip
              wrapperStyle={{ zIndex: 10000 }}
              content={
                <AlarmTimelineTooltip getBucketAlarms={getBucketAlarms} timezone={timezone} />
              }
              filterNull={false}
              cursor={false}
            />
            <XAxis
              dataKey="bucket"
              tick={{ stroke: "#bebebe", strokeWidth: 0.1 }}
              axisLine={false}
              domain={["dataMin", "dataMax"]}
              tickFormatter={GraphUtil.getTickFormatter(displayRange)}
              interval={GraphUtil.calculateTickInterval(displayRange.range)}
              style={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "8px",
              }}
              xAxisId={0}
            />
            {/* Line necessary to show tooltip */}
            <Line dataKey="none" />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <AlarmsModal
        timezone={timezone}
        open={modalOpen}
        title="Selected Alarms"
        getBucketAlarms={getBucketAlarms}
        onClose={() => setModalOpen(false)}
        alarms={selectedAlarms}
        subtitleTimeStart={currentBigBucket?.start}
        subtitleTimeStop={currentBigBucket?.stop}
      />
    </>
  );
};

AlarmTimeline.propTypes = {
  timeSeries: PropTypes.array,
  displayRange: PropTypes.object,
  bigBuckets: PropTypes.array,
  alarmHistoryData: PropTypes.object,
  position: PropTypes.object,
  axisOnly: PropTypes.bool,
  getBucketAlarms: PropTypes.func,
  timezone: PropTypes.string,
  leftMargin: PropTypes.number,
  rightMargin: PropTypes.number,
};
export default AlarmTimeline;
