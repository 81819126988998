/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { getAlarmFormattedTimeString, getAlarmFormattedDateString } from "../../../lib/Util";
import AlarmCircle from "./AlarmCircle";

const AlarmDetail = ({ alarmSeverity, alarmDate, alarmSource, timezone, alarmName, resolved }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="baseline">
      <Box pr="1em">
        <AlarmCircle alarmSeverity={alarmSeverity} resolved={resolved} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box className="tooltip-value">{alarmName}</Box>
        <Box className="tooltip-alarm-type">{alarmSource}</Box>
        <Box
          className="tooltip-alarm-type"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box pr="2rem">{getAlarmFormattedTimeString(alarmDate, timezone)}</Box>
          <Box>{getAlarmFormattedDateString(alarmDate, timezone)}</Box>
        </Box>
      </Box>
    </Box>
  );
};

AlarmDetail.propTypes = {
  alarmSeverity: PropTypes.string.isRequired,
  alarmDate: PropTypes.string.isRequired,
  alarmSource: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  alarmName: PropTypes.string.isRequired,
  resolved: PropTypes.bool.isRequired,
};

export default AlarmDetail;
