import React from "react";

import { Typography, MenuList, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

const ScopeSelector = ({ selected, list, handleChange }) => {
  // add an 'all' option to the list
  if (Array.isArray(list) && list[0]?.uuid !== "all")
    list.unshift({ uuid: "all", name: "ALL" }, { uuid: "offgrid", name: "OFF GRID" });

  return (
    <MenuList>
      {list?.map((item) => {
        return (
          <MenuItem
            selected={item.uuid === selected}
            key={item.uuid}
            onClick={() => handleChange(item.uuid)}
          >
            <Typography noWrap variant="subtitle1" color="secondary">
              {item.name}
            </Typography>
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

ScopeSelector.propTypes = {
  list: PropTypes.array,
  selected: PropTypes.string,
  handleChange: PropTypes.func,
};

export default ScopeSelector;
