/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import AlarmCircle from "./AlarmCircle";
import { sortAlarmsIncludingColor } from "../../../lib/AlarmUtil";

import { getAlarmFormattedTimeString, getAlarmFormattedDateString } from "../../../lib/Util";

const AlarmsModal = ({
  open,
  title,
  subtitle = null,
  onClose,
  alarms,
  subtitleTimeStart,
  subtitleTimeStop,
  hasAssets = true,
}) => {
  const theme = useTheme();

  if (!alarms?.length) return null;
  const hasStopTime = alarms?.some((alarm) => alarm.stop_time);

  const addIndex = (alarms) => {
    return alarms.map((alarm, index) => {
      return { ...alarm, id: index };
    });
  };

  const getFormattedDate = (date) => {
    if (!date) return null;
    const formattedDate = getAlarmFormattedDateString(date);
    const formattedTime = getAlarmFormattedTimeString(date);
    return `${formattedDate} ${formattedTime}`;
  };

  const getAlarmsFormattedTimeRange = () => {
    if (!(subtitleTimeStart && subtitleTimeStop)) return null;
    const start = getFormattedDate(subtitleTimeStart);
    const stop = getFormattedDate(subtitleTimeStop);
    return `${start} - ${stop}`;
  };

  const getAlarmCircle = (alarmSeverity, resolved) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AlarmCircle alarmSeverity={alarmSeverity} resolved={resolved} />
      </Box>
    );
  };

  const standardize_date = (alarms) => {
    return alarms.map((alarm) => {
      if (!alarm.date && alarm.timestamp) {
        return { ...alarm, date: alarm.timestamp };
      } else {
        return alarm;
      }
    });
  };

  const standardize_name = (alarms) => {
    return alarms.map((alarm) => {
      if (!alarm.name && alarm.description) {
        return { ...alarm, name: alarm.description };
      } else {
        return alarm;
      }
    });
  };

  const processAlarms = (alarms) => {
    alarms = standardize_date(alarms);
    alarms = standardize_name(alarms);
    alarms = sortAlarmsIncludingColor(alarms);
    alarms = addIndex(alarms);
    return alarms;
  };

  const columns = [
    {
      field: "alarm_type",
      headerName: "",
      width: 70,
      renderCell: (params) => getAlarmCircle(params.row.alarm_type, params.row.stop_time),
    },
    {
      field: "description",
      headerName: "Alarm Name",
      width: 300,
    },
    {
      field: "type",
      headerName: "Asset",
      width: 100,
    },
    {
      field: "timestamp",
      headerName: "Alarm Time",
      width: 200,
      valueGetter: (value) => getFormattedDate(value),
    },
    {
      field: "stop_time",
      headerName: "Time Resolved",
      width: 200,
      valueGetter: (value) => getFormattedDate(value) || "—",
    },
  ];

  const styles = {
    dialogBoxContents: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      boxShadow: 24,
      borderRadius: 1,
      maxWidth: "none",
      maxHeight: "90vh",
      height: "80vh",
      py: 6,
      px: 5,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      outline: "none !important",
    },
    datagrid: {
      border: "none",
    },
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.dialogBoxContents}>
        <Typography variant="h4" color="black" mb=".6rem">
          {title}
        </Typography>
        <Typography variant="Subtitle1" color="gray" sx={{ mb: "2.5rem" }}>
          {subtitle || getAlarmsFormattedTimeRange()}
        </Typography>
        <DataGrid
          rows={processAlarms(alarms)}
          columns={columns}
          initialState={{
            pagination: false,
          }}
          hideFooter={true}
          disableRowSelectionOnClick
          columnVisibilityModel={{ stop_time: hasStopTime, type: hasAssets }}
          sx={styles.datagrid}
          disableColumnSelector={true}
          disableColumnResize={true}
        />
      </Box>
    </Modal>
  );
};

AlarmsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  subtitleTimeStart: PropTypes.string,
  subtitleTimeStop: PropTypes.string,
  alarms: PropTypes.array,
  hasAssets: PropTypes.bool,
};
export default AlarmsModal;
