/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import { Typography, Box, FormGroup, Divider, Breadcrumbs, Link, Button } from "@mui/material";
// import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import OrganizationsTable from "./OrganizationTable";
import { useNavigate } from "react-router-dom";

const Settings = ({ flags }) => {
  const theme = useTheme();

  const styles = {
    caption: {
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "1rem",
    },
    subtitle: {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "700",
    },
    settingsPanel: {
      width: "100%",
      py: "2rem",
      px: "3rem",
    },
    saveContainer: {
      pt: "2em",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-end",
      marginBottom: "2rem",
    },
  };

  const getOrganizationPanel = () => (
    <Box sx={[theme.classes.genericPanel, { marginTop: "2rem" }]}>
      <Typography variant="subtitle2" sx={styles.subtitle}>
        All Organizations
      </Typography>
      <Box sx={styles.settingsPanel}>
        <FormGroup>
          <Box display="flex" flexDirection="column" width="100%">
            <OrganizationsTable />
          </Box>
        </FormGroup>
      </Box>
    </Box>
  );

  const navigate = useNavigate();

  return (
    <Box sx={theme.classes.pageContainer}>
      <Box sx={theme.classes.page}>
        <Box>
          {/* TODO standardize this title bar into component so styles are not repeated */}
          <Typography sx={{ mb: "1.5rem", fontSize: "24px", fontWeight: 600 }} variant="h5">
            Admin
          </Typography>
          <Divider />
          <Breadcrumbs aria-label="breadcrumb" sx={{ ...styles.caption, mt: "32px" }}>
            <Button
              onClick={() => navigate("/settings")}
              sx={{ fontSize: 16, textDecoration: "none", color: "inherit" }}
            >
              Organizations
            </Button>
          </Breadcrumbs>
          {getOrganizationPanel()}
        </Box>
      </Box>
    </Box>
  );
};

Settings.propTypes = {
  flags: PropTypes.object,
};

export default Settings;
