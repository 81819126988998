/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

export type AlarmData = {
  name: string;
  type: string;
  date: string;
  alarm_type: string;
  assembly_uuid: string;
  stop_time: null | string;
  source: string;
};

export const bucketAlarmsByTime = (alarms: AlarmData[]): { [key: string]: AlarmData[] } => {
  const buckets: { [key: string]: AlarmData[] } = {};

  alarms.forEach((alarm) => {
    const date = new Date(alarm.date);
    const key = date.toISOString();

    if (buckets[key]) {
      buckets[key].push(alarm);
    } else {
      buckets[key] = [alarm];
    }
  });

  return buckets;
};

export const sortAlarmsAlphabetically = (alarms: AlarmData[]) => {
  const alphabeticallySortedAlarms = alarms.sort((a, b) =>
    a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true,
    }),
  );

  return alphabeticallySortedAlarms;
};

export const sortAlarmsByResolved = (alarms: AlarmData[]) => {
  const resolvedAlarms = alarms.filter((alarm) => alarm.stop_time);
  const unresolvedAlarms = alarms.filter((alarm) => !alarm.stop_time);

  return [...unresolvedAlarms, ...resolvedAlarms];
};

export const sortAlarms = (alarms: AlarmData[]) => {
  if (alarms.length === 0) return [];

  const timeSortedAlarms = alarms.sort((a, b) => {
    return new Date(a.date).valueOf() - new Date(b.date).valueOf();
  });

  let alarmsSecondBucketed = bucketAlarmsByTime(timeSortedAlarms);

  for (const key in alarmsSecondBucketed) {
    alarmsSecondBucketed[key] = sortAlarmsAlphabetically(alarmsSecondBucketed[key]);
  }

  let sortedAlarms = [];
  for (const key in alarmsSecondBucketed) {
    sortedAlarms.push(...alarmsSecondBucketed[key]);
  }

  const resolveSortedAlarms = sortAlarmsByResolved(sortedAlarms);

  return resolveSortedAlarms;
};

export const getRedAlarms = (alarms: AlarmData[]) => {
  if (!alarms) return [];
  return alarms.filter((alarm) => alarm.alarm_type === "Red");
};

export const getAmberAlarms = (alarms: AlarmData[]) => {
  if (!alarms) return [];
  return alarms.filter((alarm) => alarm.alarm_type === "Amber");
};

export const sortAlarmsIncludingColor = (alarms: AlarmData[]) => {
  if (alarms.length === 0) return [];
  const redAlarms = getRedAlarms(alarms);
  const amberAlarms = getAmberAlarms(alarms);

  const sortedRedAlarms = sortAlarms(redAlarms);
  const sortedAmberAlarms = sortAlarms(amberAlarms);

  return [...sortedRedAlarms, ...sortedAmberAlarms];
};
