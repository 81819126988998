/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import dayjs from "dayjs";
import theme from "../style/theme";
import { ReferenceDot } from "recharts";
import { toISOStringWithTimezone } from "./Util";
const GraphUtil = {};

/**
 * Once we have the raw series data, we need to decorate it with some things. First, is the "big buckets".  This is the coarse grained bucket into which things like alarms fall.
 */
GraphUtil.calculateBigBuckets = (timeSeries) => {
  // if we dont' have data, we can't do anything
  if (!timeSeries?.length) return;

  let newBigBuckets = [];
  const bucketFactor = timeSeries.length >= 10 ? 10 : timeSeries.length;
  const middle = bucketFactor / 2;
  let index = 0;
  let currentBucket = timeSeries[middle].bucket;
  let bucketStart = timeSeries[0].bucket;
  let bucketStop = timeSeries[bucketFactor - 1].bucket;
  timeSeries.forEach((bucket) => {
    if (index === middle) {
      currentBucket = bucket.bucket;
    }
    if (index === 0) {
      bucketStart = bucket.bucket;
      // use stop of previous big bucket as start of next one, if it exists.
      // add one millisecond so they don't overlap
      if (newBigBuckets.length > 0) {
        let lastEnd = new Date(newBigBuckets[newBigBuckets.length - 1].stop).getTime() + 1;
        lastEnd = dayjs(lastEnd).format("YYYY-MM-DDTHH:mm:ss.SSS");
        bucketStart = lastEnd;
      }
    }
    if (index === bucketFactor - 1) {
      bucketStop = bucket.bucket;
    }
    bucket.bigBucket = currentBucket;
    index++;
    if (index === bucketFactor) {
      index = 0;
      newBigBuckets.push({
        start: bucketStart,
        stop: bucketStop,
        middle: currentBucket,
      });
    }
  });
  return { newBigBuckets, timeSeries };
};

GraphUtil.calculateDataInterval = (hours) => {
  let interval = hours * 60 * 60;
  interval = interval / 720;
  // NTM - Interval cannot be zero or less than zero or Timescale throws a fit (understandably)
  return interval <= 0 ? 1 : interval;
};

GraphUtil.calculateTickInterval = (range) => {
  let interval = 0;
  switch (range) {
    case "today":
      interval = 59;
      break;
    case "yesterday":
      interval = 59;
      break;
    case "7days":
      interval = 102;
      break;
    case "custom":
      interval = 59;
      break;
    default:
      interval = 102;
      break;
  }

  return interval;
};

GraphUtil.getTickFormatter = (displayRange) => {
  // if our time span is 24 hours or less, show time.  Otherwise, show date.
  let timeSpan = new Date(displayRange.stop).getTime() - new Date(displayRange.start).getTime();
  if (timeSpan <= 86400000) {
    return (bucketDate) => {
      let d = new Date(bucketDate);
      return d.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    };
  } else {
    return (bucketDate) => {
      let d = new Date(bucketDate);
      return d.toLocaleDateString("en-US", {
        dateStyle: "medium",
      });
    };
  }
};

GraphUtil.getAlarmTimelineArea = (areaProps) => {
  return (
    <rect
      x={areaProps.x}
      y={areaProps.y}
      width={areaProps.width}
      height={"20px"}
      fill={theme.palette.grey.light}
    />
  );
};

GraphUtil.getReferenceDot = (dotProps, bucketAlarms, isSelected = false) => {
  //TODO:  Get the correct color based on the alarms in the array
  let severityColor =
    bucketAlarms.severity === "Red" ? theme.palette.error.main : theme.palette.warning.main;
  let textColor = "#FFF";
  const dot = (
    <g style={{ cursor: "pointer" }}>
      <circle
        style={{
          fill: bucketAlarms.resolved ? textColor : severityColor,
          stroke: bucketAlarms.resolved ? severityColor : textColor,
          strokeWidth: isSelected ? 2 : 1,
          strokeMiterlimit: 10,
          filter: isSelected ? "drop-shadow( 0px 4px 3px rgba(0, 0, 0, .35))" : "none",
        }}
        cx={dotProps.cx}
        cy={dotProps.cy}
        r="8.5"
      ></circle>
      <text
        fontSize={9}
        x={dotProps.cx}
        y={dotProps.cy}
        textAnchor="middle"
        alignmentBaseline="central"
        fill={bucketAlarms.resolved ? severityColor : textColor}
        fontFamily="Inter, Arial, Helvetica, sans-serif"
        fontWeight="500"
      >
        {bucketAlarms.alarms.length}
      </text>
    </g>
  );
  return dot;
};

GraphUtil.getBucketAlarms = (bigBucket, alarmHistoryData) => {
  let bucketAlarms = [];

  // occaisionally, during load, we get a null big bucket.  if that happens, return empty array
  if (!bigBucket) return bucketAlarms;

  let bbStartTime = new Date(bigBucket.start).getTime();
  let bbStopTime = new Date(bigBucket.stop).getTime();
  let resolved = true;
  let severity = "Amber";
  if (alarmHistoryData?.historical_alerts) {
    alarmHistoryData.historical_alerts.forEach((alarm) => {
      let alarmTime = new Date(alarm.timestamp).getTime();
      if (bbStartTime <= alarmTime && bbStopTime >= alarmTime) {
        bucketAlarms.push(alarm);
        if (alarm.stop_time === null) {
          resolved = false;
        }
        if (alarm.alarm_type === "Red") {
          severity = "Red";
        }
      }
    });
  }
  return { severity: severity, resolved: resolved, alarms: bucketAlarms };
};

GraphUtil.getAlarmTimelineDots = (
  bigBuckets,
  alarmHistoryData,
  currentBigBucketTime,
  tooltipVisible,
) => {
  let refDots = [];
  bigBuckets.forEach((bb) => {
    let bucketAlarms = GraphUtil.getBucketAlarms(bb, alarmHistoryData);
    if (bucketAlarms.alarms.length > 0) {
      const selected = bb.middle === currentBigBucketTime && tooltipVisible;
      refDots.push(
        <ReferenceDot
          r={8}
          key={bb.stop}
          stroke="none"
          y={65}
          x={bb.stop}
          shape={(dotProps) => GraphUtil.getReferenceDot(dotProps, bucketAlarms, selected)}
        />,
      );
    }
  });
  return refDots;
};

GraphUtil.getBucketAlarmsByMiddle = (targetBucket, bigBuckets, alarmHistoryData) => {
  const bb = bigBuckets.find((b) => b.middle === targetBucket);
  return GraphUtil.getBucketAlarms(bb, alarmHistoryData);
};

/** Generate a closure function that gets alarms from the selected bucket aggrigation.  We currently use this closure since the payload can change depending on what is hovered.
 
TODO We could avoid this closure and pass a simple array of selected alarms by attaching a hover event to the main graph and creating state to store the selected alarms.  This system would still need to be implemented in each graph (SitePowerGraph, TimeSeriesLineGraph and AlarmsTimelineGraph), so for now I have simply standardized the closure functions here.
*/
GraphUtil.generateGetBucketAlarms = (bigBuckets, alarmHistoryData) => {
  const getBucketAlarms = (payload) => {
    if (payload?.length === 0) return [];
    const bigBucket = payload[0].payload.bigBucket;
    const alarms = GraphUtil.getBucketAlarmsByMiddle(
      bigBucket,
      bigBuckets,
      alarmHistoryData,
    ).alarms;
    return alarms;
  };

  return getBucketAlarms;
};

GraphUtil.handleZoomStart = (ev, zoomParams, setZoomParams) => {
  // NTM - activeLabel property of the event used to be the date, now it appears to be an index value?
  // did something change in Recharts?
  if (ev.activePayload?.length) {
    let newParams = { ...zoomParams };
    newParams.zooming = true;
    newParams.start = ev.activePayload[0].payload.bucket;
    newParams.startIndex = ev.activeLabel;
    newParams.stop = ev.activePayload[0].payload.bucket;
    newParams.stopIndex = ev.activeLabel;
    setZoomParams(newParams);
  }
};

GraphUtil.handleZoomUpdate = (ev, zoomParams, setZoomParams) => {
  if (zoomParams.zooming && ev?.activePayload?.length) {
    let newParams = { ...zoomParams };
    newParams.stop = ev.activePayload[0].payload.bucket;
    newParams.stopIndex = ev.activeLabel;
    setZoomParams(newParams);
  }
};

// need to set the interval before we update the range
GraphUtil.updateDisplayRange = (range) => {
  // we have to calculate interval here becuase several things can affect the displayed range
  // when the range updates, the interval needs to update as well.
  const startTime = new Date(range.start).getTime();
  const stopTime = new Date(range.stop).getTime();
  const interval = Math.round(
    GraphUtil.calculateDataInterval(Math.abs(stopTime - startTime) / 36e5),
  );
  range.interval = interval;
  return range;
};

/**
 * When the user releases the mouse, that signals the end of a zoom event.
 * set zooming to false, to hide the reference area that defines the zoom.
 * set the zoom stop time
 * update the display range, which triggers re-fetching data and re-rendering.
 * @param {*} ev
 */
GraphUtil.handleZoomEnd = (ev, zoomParams, setZoomParams, timezone, displayRange) => {
  if (ev.activePayload?.length) {
    // new params is the zoom parameters, start, stop, zooming, zoomed, etc
    let newParams = { ...zoomParams };
    newParams.zooming = false;
    newParams.zoomed = true;
    newParams.stop = ev.activePayload[0].payload.bucket;
    newParams.stopIndex = ev.activeLabel;
    setZoomParams(newParams);

    // new range is the calculated range used by the API
    let newRange = { ...displayRange };
    newRange.start = newParams.start;
    newRange.stop = newParams.stop;
    newRange.range = "custom";

    // if start and stop are different, then we can zoom!
    if (newParams.start !== newParams.stop) {
      //first, make sure that end is after start, if not reverse 'em.
      // this handles the right to left drag case.
      if (new Date(newParams.start).getTime() > new Date(newParams.stop).getTime()) {
        let swap = newParams.start;
        newRange.start = newRange.stop;
        newRange.stop = swap;
      }

      newRange.start = toISOStringWithTimezone(newRange.start, timezone);
      newRange.stop = toISOStringWithTimezone(newRange.stop, timezone);
      return newRange;
    }
  }
};

GraphUtil.getInitialGraphRange = (timezone) => {
  let start = new Date();
  let stop = new Date();
  start.setHours(0, 0, 0, 0);
  stop.setHours(23, 59, 59, 999);
  return {
    range: "today",
    start: toISOStringWithTimezone(start, timezone),
    stop: toISOStringWithTimezone(stop, timezone),
    interval: 120,
  };
};

export default GraphUtil;
