/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import axios from "axios";

const basePresentationUrl = process.env.REACT_APP_SMS_NOC_PRESENTATION;

/*
NTM - Create an internal instance.  This instance has the interceptors
attached to handle things like 401 and 403.  We do this so we can create
another instance without the interceptors attached for certain cases.
*/
const authenticatedInstance = axios.create({ withCredentials: true });

/** Don't logout for endpoints related to user login system */
const shoudlIgnoreLogout = (url) => {
  return url.includes("user");
};

const registerResponseInterceptor = (authLogout) => {
  const responseErrorInterceptor = (error) => {
    // check the request error code. If it is an auth problem, redirect to the login page.
    if (
      !shoudlIgnoreLogout(error.config.url) &&
      (error.response?.status === 401 || error.response?.status === 403)
    ) {
      // if we can't get a response here, then try to call the
      // user info API.  Did the user accidentally hit something they shouldn't have?
      // If we can't validate the user then ... ?
      authLogout({ logoutParams: { returnTo: window.location.origin } });
    }
    return Promise.reject(error);
  };

  authenticatedInstance?.interceptors.response.use((res) => res, responseErrorInterceptor);
};

const isScaleDomain = (url) => {
  return url.includes(basePresentationUrl);
};

const registerRequestInterceptor = (getAccessTokenSilently) => {
  const requestAuthInterceptor = async (config) => {
    if (!isScaleDomain(config.url)) {
      return config;
    }

    try {
      const credential = await getAccessTokenSilently();

      config.headers = {
        Authorization: "Bearer " + credential,
        "content-type": "application/json",
      };
    } catch (error) {
      // request for credential failed so continue without one
    }
    // return scale domain request with or without credential
    return config;
  };

  authenticatedInstance.interceptors.request.use(requestAuthInterceptor, (req) => req);
};

const getPortfolioPower = () => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/portfolio/power/production`)
    .then((res) => {
      return res.data;
    });
};

const getSiteTimeSeries = (siteUuid, start, stop, interval) => {
  const params = {
    interval: interval,
    start_time: start,
    stop_time: stop,
  };
  return authenticatedInstance

    .get(`${basePresentationUrl}/site/${siteUuid}/time-series`, { params })
    .then((res) => res.data);
};
const getSitePowerCapacity = (siteUuid) => {
  return authenticatedInstance

    .get(`${basePresentationUrl}/site/${siteUuid}/power/capacity`)
    .then((res) => res.data);
};
const getSiteEnergyProduction = (siteUuid, start, stop, interval) => {
  const params = {
    interval: interval,
    start_time: start,
    stop_time: stop,
  };
  return authenticatedInstance

    .get(`${basePresentationUrl}/site/${siteUuid}/energy/production`, {
      params,
    })
    .then((res) => res.data);
};
const getSiteAssemblies = (siteUuid, interval) => {
  const params = { interval: interval };
  return authenticatedInstance

    .get(`${basePresentationUrl}/site/${siteUuid}/assemblies`, { params })
    .then((res) => res.data);
};
export const getUserProfile = () => {
  return authenticatedInstance.get(`${basePresentationUrl}/user`).then((res) => res.data);
};
const getAlarmDetail = (siteUuid, alarmUuid) => {
  return authenticatedInstance

    .get(`${basePresentationUrl}/site/${siteUuid}/alarm/${alarmUuid}`)
    .then((res) => res.data);
};
const getSiteMetadata = (siteUuid) => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}`)
    .then((res) => res.data);
};
export const getAlarmHistory = (siteUuid, start, stop) => {
  const params = {
    start_time: start,
    stop_time: stop,
  };
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/alarms/history`, { params })
    .then((res) => res.data);
};
const getTimeSeriesMetrics = (siteUuid, start, stop, interval, metricsUuids) => {
  const params = {
    start_time: start,
    stop_time: stop,
    interval: interval,
    metrics: metricsUuids.toString(),
  };
  return authenticatedInstance

    .get(`${basePresentationUrl}/site/${siteUuid}/time-series/metrics`, {
      params,
    })
    .then((res) => res.data);
};
const getIsoList = () => {
  return authenticatedInstance.get(`${basePresentationUrl}/iso/list`).then((res) => res.data);
};
const getUtilityList = (filter) => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/utilities/list?filter=${filter}`)
    .then((res) => res.data);
};
const getResourceList = (entity, scope) => {
  const params = {};
  if (entity !== "all") params[scope] = entity;
  return authenticatedInstance
    .get(`${basePresentationUrl}/resources/list`, { params })
    .then((res) => res.data);
};
const getSiteList = async () => {
  try {
    const response = await authenticatedInstance.get(`${basePresentationUrl}/sites/list`);
    return response.data;
  } catch (error) {
    throw new Error("Problem getting site list.");
  }
};

const getOrganizationsList = async () => {
  try {
    const response = await authenticatedInstance.get(
      `${basePresentationUrl}/acct_mgmt/customer/list`,
    );
    return response.data;
  } catch (error) {
    throw new Error("Problem getting organizations list.");
  }
};

const getOrganizationSiteList = async (organizationUuid) => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/acct_mgmt/organization/sites/list/${organizationUuid}`)
    .then((res) => res.data);
};

const getCurrentAlarms = async (siteUuid) => {
  return authenticatedInstance
    .get(`${process.env.REACT_APP_SMS_NOC_PRESENTATION}/site/${siteUuid}/alarm`)
    .then((res) => res.data);
};

const getOrganizationUsersList = async (organizationUuid) => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/acct_mgmt/customer/${organizationUuid}/users`)
    .then((res) => res.data);
};

const getPortfolioPowerQuery = () => {
  return {
    queryKey: ["portfolio-power"],
    queryFn: () => getPortfolioPower(),
    refetchInterval: 120000,
  };
};

const getSiteTimeSeriesQuery = (siteUuid, start, stop, interval) => {
  return {
    queryKey: ["site-time-series-" + siteUuid],
    queryFn: () => getSiteTimeSeries(siteUuid, start, stop, interval),
    // Refetch the data every 15 seconds
    refetchInterval: 15000,
  };
};

const getSitePowerCapacityQuery = (siteUuid) => {
  return {
    queryKey: ["site-power-capacity-" + siteUuid],
    queryFn: () => getSitePowerCapacity(siteUuid),
    refetchInterval: 15000,
  };
};
const getSiteEnergyProductionQuery = (siteUuid, start, stop, interval) => {
  return {
    queryKey: ["site-energy-production-" + siteUuid],
    queryFn: () => getSiteEnergyProduction(siteUuid, start, stop, interval),
    refetchInterval: 15000,
  };
};
const getSiteAssembliesQuery = (siteUuid) => {
  return {
    queryKey: ["site-assemblies-" + siteUuid],
    queryFn: () => getSiteAssemblies(siteUuid, 120),
  };
};
const getUserProfileQuery = () => {
  return {
    queryKey: ["user-profile"],
    queryFn: () => getUserProfile(),
  };
};
const getUserProfileMutation = () => {
  return {
    mutationKey: ["user-profile"],
    mutationFn: (userProfile) => {
      return authenticatedInstance.put(`${basePresentationUrl}/user`, userProfile);
    },
  };
};
const getSiteSettingsMutation = () => {
  return {
    mutationKey: ["site-settings"],
    mutationFn: (siteSettings) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/site/${siteSettings.uuid}`,
        siteSettings,
      );
    },
  };
};

const putUserSettingsMutation = () => {
  return {
    mutationKey: ["user-settings"],
    mutationFn: (userSettings) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/acct_mgmt/user/update`,
        userSettings,
      );
    },
  };
};

const putOrganizationSettingsMutation = () => {
  return {
    mutationKey: ["organization-settings"],
    mutationFn: (organizationSettings) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/acct_mgmt/organization/${organizationSettings.uuid}`,
        organizationSettings,
      );
    },
  };
};

const postOrganizationSettingsMutation = () => {
  return {
    mutationKey: ["new-organization"],
    mutationFn: (organizationSettings) => {
      return authenticatedInstance.post(
        `${basePresentationUrl}/acct_mgmt/customer/add`,
        organizationSettings,
      );
    },
  };
};

const postSiteSettingsMutation = () => {
  return {
    mutationKey: ["new-site"],
    mutationFn: (siteSettings) => {
      return authenticatedInstance.post(`${basePresentationUrl}/site`, siteSettings);
    },
  };
};

const postUserSettingsMutation = () => {
  return {
    mutationKey: ["new-user"],
    mutationFn: (userSettings) => {
      return authenticatedInstance.post(`${basePresentationUrl}/acct_mgmt/user`, userSettings);
    },
  };
};

const deleteOrganizationMutation = () => {
  return {
    mutationKey: ["organization-delete"],
    mutationFn: (organizationUuid) => {
      return authenticatedInstance.delete(
        `${basePresentationUrl}/acct_mgmt/customer/${organizationUuid}`,
      );
    },
  };
};

const deleteUserMutation = () => {
  return {
    mutationKey: ["user-delete"],
    mutationFn: (userEmail) => {
      return authenticatedInstance.delete(`${basePresentationUrl}/acct_mgmt/user/${userEmail}`);
    },
  };
};

const getAlarmDetailQuery = (siteUuid, alarmUuid) => {
  return {
    queryKey: ["alarm-detail-" + siteUuid + "-" + alarmUuid],
    queryFn: () => getAlarmDetail(siteUuid, alarmUuid),
  };
};
const getSiteMetadataQuery = (siteUuid) => {
  return {
    queryKey: ["site-metadata-" + siteUuid],
    queryFn: () => getSiteMetadata(siteUuid),
  };
};
const getAlarmHistoryQuery = (siteUuid, start, stop) => {
  return {
    queryKey: ["alarm-history-" + siteUuid],
    queryFn: () => getAlarmHistory(siteUuid, start, stop),
    refetchInterval: 15000,
  };
};

const getTimeSeriesMetricsQuery = (siteUuid, start, stop, interval, metricsUuids) => {
  return {
    queryKey: ["time-series-metrics-" + siteUuid + "-" + metricsUuids.toString()],
    queryFn: () => getTimeSeriesMetrics(siteUuid, start, stop, interval, metricsUuids),
  };
};

const getIsoListQuery = () => {
  return {
    queryKey: ["iso-list"],
    queryFn: () => getIsoList(),
  };
};

const getUtilityListQuery = (filter) => {
  return {
    queryKey: ["utility"],
    queryFn: () => getUtilityList(filter),
  };
};

const getResourceListQuery = (entity, scope) => {
  return {
    queryKey: ["resource-" + scope + "-" + entity],
    queryFn: () => getResourceList(entity, scope),
  };
};

const getSiteListQuery = () => {
  return {
    queryKey: ["site-list"],
    queryFn: () => getSiteList(),
  };
};

const getOrganizationsListQuery = () => {
  return {
    queryKey: ["organizations-list"],
    queryFn: () => getOrganizationsList(),
  };
};

const getOrganizationSitesListQuery = (organizationUuid) => {
  return {
    queryKey: ["organization-sites-list-" + organizationUuid],
    queryFn: () => getOrganizationSiteList(organizationUuid),
  };
};

const getCurrentAlarmsQuery = (siteUuid) => {
  return {
    queryKey: ["current-alarms-" + siteUuid],
    queryFn: () => getCurrentAlarms(siteUuid),
  };
};

const getOrganizationUsersListQuery = (organizationUuid) => {
  return {
    queryKey: ["organization-users-" + organizationUuid],
    queryFn: () => getOrganizationUsersList(organizationUuid),
  };
};

export {
  getPortfolioPowerQuery,
  getSiteTimeSeriesQuery,
  getSitePowerCapacityQuery,
  getSitePowerCapacity,
  getSiteEnergyProductionQuery,
  getSiteAssembliesQuery,
  getUserProfileQuery,
  getUserProfileMutation,
  getSiteSettingsMutation,
  getAlarmDetailQuery,
  getSiteMetadataQuery,
  getAlarmHistoryQuery,
  getCurrentAlarmsQuery,
  getCurrentAlarms,
  getTimeSeriesMetricsQuery,
  getIsoListQuery,
  getUtilityListQuery,
  getResourceListQuery,
  getSiteTimeSeries,
  getTimeSeriesMetrics,
  getSiteList,
  getSiteListQuery,
  getOrganizationsListQuery,
  putOrganizationSettingsMutation,
  postOrganizationSettingsMutation,
  deleteOrganizationMutation,
  getOrganizationSitesListQuery,
  postSiteSettingsMutation,
  getOrganizationUsersListQuery,
  postUserSettingsMutation,
  deleteUserMutation,
  putUserSettingsMutation,
  registerRequestInterceptor,
  registerResponseInterceptor,
};
