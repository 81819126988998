/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const OrganizationLink = ({ organization, variant, color }) => {
  console.log("Organization being linked:", organization);
  // const handleClick = (e) => {
  //   localStorage.setItem("organization", `${organization.uuid}`);
  // };

  return (
    <Link
      to={organization.uuid ? `/organization/${organization.name.replace(/ /g, "-")}` : null}
      state={{ organization }}
    >
      <ChevronRightIcon
        sx={{
          color: "rgba(0, 0, 0, 0.56)",
          fontSize: "large",
        }}
      />
    </Link>
  );
};

OrganizationLink.propTypes = {
  organization: PropTypes.object.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default OrganizationLink;
