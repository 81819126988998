import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box, Tabs, Tab, Typography, SvgIcon, Tooltip } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import AssetTable from "./AssetTable";
import EventTable from "./EventTable";
import ScopeSelector from "./ScopeSelector";

import {
  getPortfolioPowerQuery,
  getIsoListQuery,
  getUtilityListQuery,
  getResourceListQuery,
} from "../../lib/Queries";

import Map, { Marker, Source, Layer, Popup } from "react-map-gl";

import DemoData from "./DemoData";
import { useSearchParams } from "react-router-dom";

import { getMarkerColor } from "../../lib/Util";
import MapPopup from "../Portfolio/MapPopup";

const Markets = () => {
  const flags = useFlags();
  const [searchParams] = useSearchParams();
  const demoMode = flags.portfolioDemoMode || searchParams.get("mode") === "demo";

  const accessToken =
    "pk.eyJ1Ijoibm1jbWlubiIsImEiOiJjbHMyOGJta2gwOWkyMm1sOHFkazl4aHZzIn0.aWlKbypBeiMOZl-g1GiUzw";

  const [activeDERTab, setActiveDERTab] = useState(0);
  const [activeScopeTab, setActiveScopeTab] = useState(0);
  const [totalSites, setTotalSites] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [totalOutput, setTotalOutput] = useState(0);
  const [totalStored, setTotalStored] = useState(0);
  const [lng] = useState(-99.2517);
  const [lat] = useState(40.168);
  const [popupSite, setPopupSite] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [zoom] = useState(3);
  const [entity, setEntity] = useState("all");
  const [scope, setScope] = useState("iso");
  const [syntheticSoC, setSyntheticSoC] = useState([]);
  const [syntheticSolar, setSyntheticSolar] = useState([]);
  const [resourceList, setResourceList] = useState([]);
  const [localData, setLocalData] = useState({ sites: [] });

  const { data } = useQuery({
    ...getPortfolioPowerQuery(),
    refetchInterval: false,
  });

  const { data: isoListData } = useQuery({
    ...getIsoListQuery(),
  });

  const { data: utilityListData } = useQuery({
    ...getUtilityListQuery(true),
  });

  const { data: resourceListData } = useQuery({
    ...getResourceListQuery(entity, scope),
    enabled: Boolean(entity) && Boolean(scope),
  });

  useEffect(() => {
    if (data) {
      if (demoMode) {
        setLocalData({ sites: data.sites.concat(DemoData.sites) });
      } else {
        setLocalData({ sites: data.sites });
      }
    }
  }, [data]);

  useEffect(() => {
    setResourceList(resourceListData);
  }, [resourceListData]);

  useEffect(() => {
    // create some arrays of synthetic BESS and Solar data
    let soc = [];
    let solar = [];
    for (let i = 0; i < 20; i++) {
      solar.push(Math.round(Math.random() * (95 - 80) + 80));
      soc.push(Math.round(Math.random() * (95 - 30) + 30));
    }
    setSyntheticSoC(soc);
    setSyntheticSolar(solar);
  }, []);

  useEffect(() => {
    if (resourceListData) {
      let updatedResourceList = { ...resourceListData };
      if (!updatedResourceList.Genset) {
        updatedResourceList.Genset = { array: [] };
      }
      if (!updatedResourceList.Solar) {
        updatedResourceList.Solar = { array: [] };
      }
      if (!updatedResourceList.BESS) {
        updatedResourceList.BESS = { array: [] };
      }

      // only merge in the demo resources and generate synthetic values if the mode is set to "demo"
      if (demoMode) {
        if (DemoData.resources[entity]?.Genset) {
          updatedResourceList.Genset.array = updatedResourceList.Genset.array.concat(
            DemoData.resources[entity]?.Genset.array,
          );
        }
        if (DemoData.resources[entity]?.Solar) {
          updatedResourceList.Solar.array = updatedResourceList.Solar.array.concat(
            DemoData.resources[entity]?.Solar.array,
          );
        }
        if (DemoData.resources[entity]?.BESS) {
          updatedResourceList.BESS.array = updatedResourceList.BESS.array.concat(
            DemoData.resources[entity]?.BESS.array,
          );
        }

        updatedResourceList = generateSynthetics(updatedResourceList);
      }

      let capacity = 0;
      let power = 0;
      let stored = 0;
      let sites = [];
      let allResources = [];
      allResources = allResources.concat(updatedResourceList.Genset?.array);
      allResources = allResources.concat(updatedResourceList.Solar?.array);
      allResources = allResources.concat(updatedResourceList.BESS?.array);

      allResources.forEach((resource) => {
        if (resource) {
          if (!sites.includes(resource.site_uuid)) sites.push(resource.site_uuid);
          if (resource.power_capacity) capacity = capacity + resource.power_capacity;
          if (resource.power) power = power + resource.power;
          if (resource.soc && resource.energy_capacity)
            stored = stored + resource.soc * 0.01 * resource.energy_capacity;
        }
      });
      setTotalSites(sites.length);
      setTotalCapacity(Math.round(capacity));
      setTotalOutput(Math.round(power));
      setTotalStored(Math.round(stored));

      setResourceList(updatedResourceList);
    } else {
      setTotalSites(0);
      setTotalCapacity(0);
      setTotalOutput(0);
      setTotalStored(0);
    }
  }, [resourceListData]);

  const generateSynthetics = (resourceList) => {
    if (demoMode) {
      resourceList.BESS?.array.forEach((b, idx) => {
        if (!b.soc) {
          b.soc = syntheticSoC[idx];
        }
      });
      resourceList.Solar?.array.forEach((s, idx) => {
        if (!s.power) {
          s.power = syntheticSolar[idx] * 0.01 * s.power_capacity;
        }
      });
    }
    return resourceList;
  };

  const handleDERTabChange = (ev, val) => {
    setActiveDERTab(val);
  };

  const handleScopeTabChange = (ev, val) => {
    setActiveScopeTab(val);
  };

  const handleScopeSelection = (entity, scope) => {
    setEntity(entity);
    setScope(scope);
  };

  const showPopup = (site) => {
    setPopupSite(site);
    setPopupOpen(true);
  };

  const hidePopup = (ev) => {
    setPopupOpen(false);
  };

  const getMarkers = () => {
    let markers = [];
    if (localData) {
      localData.sites.forEach((site) => {
        if (site.longitude && site.latitude) {
          markers.push(
            <Marker
              key={site.uuid}
              longitude={site.longitude}
              latitude={site.latitude}
              anchor="center"
              onClick={() => {
                showPopup(site);
              }}
            >
              <SvgIcon>
                <svg>
                  <circle
                    cx="50%"
                    cy="50%"
                    r={7}
                    fill={getMarkerColor(site)}
                    stroke="#fff"
                    strokeWidth={2}
                  />
                </svg>
              </SvgIcon>
            </Marker>,
          );
        }
      });
    }
    return markers;
  };

  const getSelectedEntity = () => {
    let selectedEntity;
    if (entity && scope) {
      if (scope === "iso") {
        selectedEntity = isoListData?.iso.filter((iso) => iso.uuid === entity)[0];
      } else {
        selectedEntity = utilityListData.utilities.filter((utility) => utility.uuid === entity)[0];
      }
    }
    return selectedEntity;
  };

  const getLayers = () => {
    if (isoListData?.iso?.length > 0 && entity && scope) {
      const selectedEntity = getSelectedEntity();
      if (selectedEntity?.GeoJSON) {
        return (
          <Source id="iso" type="geojson" data={selectedEntity.GeoJSON}>
            <Layer
              id={"iso-layer"}
              type="line"
              source="iso"
              paint={{
                "line-color": "#325182",
                "line-width": 2,
              }}
            />
          </Source>
        );
      }
    }
  };

  return (
    <Box
      ml="80px"
      mt="30px"
      pl="50px"
      pr="50px"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <Typography className="dashboard" variant="h6">
          Scale Microgrids Portfolio
        </Typography>
      </Box>
      <Box>
        <div className="line"></div>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        mr="100px"
        height="450px"
        bgcolor="white"
        mb="30px"
        width="100%"
      >
        <Box display="flex" flexDirection="column" width="35%" p="20px" maxWidth="280px">
          <Tabs
            value={activeScopeTab}
            onChange={handleScopeTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="ISO" />
            <Tab label="Utility" />
          </Tabs>
          {activeScopeTab === 0 ? (
            <Box overflow="scroll">
              <ScopeSelector
                selected={entity}
                list={isoListData?.iso}
                handleChange={(entity) => handleScopeSelection(entity, "iso")}
              />
            </Box>
          ) : null}
          {activeScopeTab === 1 ? (
            <Box overflow="scroll">
              <ScopeSelector
                selected={entity}
                list={utilityListData?.utilities}
                handleChange={(entity) => handleScopeSelection(entity, "utility")}
              />
            </Box>
          ) : null}
        </Box>
        <Box width="100%" height="100%">
          <Map
            mapboxAccessToken={accessToken}
            initialViewState={{
              latitude: lat,
              longitude: lng,
              zoom: zoom,
            }}
            mapStyle="mapbox://styles/mapbox/light-v9"
          >
            {getMarkers()}
            {getLayers()}
            {popupOpen && (
              <Popup
                longitude={popupSite.longitude}
                latitude={popupSite.latitude}
                anchor="bottom"
                onClose={(ev) => {
                  hidePopup(ev);
                }}
                closeOnClick={false}
                style={{ maxWidth: "300px" }}
              >
                <MapPopup site={popupSite} />
              </Popup>
            )}
          </Map>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" color="secondary">
          {getSelectedEntity()?.name || ""}
        </Typography>
      </Box>
      <Box>
        <div className="line"></div>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          width={demoMode ? null : "100%"}
          flexDirection={demoMode ? "column" : "row"}
          justifyContent="space-around"
          mb="30px"
          borderRight={demoMode ? "1px #E3E3E3 solid" : null}
          pr="3em"
        >
          <Box display="flex" flexDirection="row" mb="2em">
            <Box
              bgcolor="white"
              mr="10px"
              width="60px"
              height="60px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #E1E2E4"
              borderRadius="5px"
            >
              <SvgIcon color="secondary" fontSize="large">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0837 9.87023V2.14808C16.0837 1.91235 15.8594 1.74128 15.6321 1.80368L1.94229 5.56165C1.78719 5.60423 1.67969 5.74522 1.67969 5.90606V25.0469C1.67969 25.2441 1.83959 25.404 2.03683 25.404H7.0459M16.0837 9.87023H24.7644C24.9617 9.87023 25.1216 10.0301 25.1216 10.2274V25.0469C25.1216 25.2441 24.9617 25.404 24.7644 25.404H16.0837M16.0837 9.87023V25.404M16.0837 25.404H10.7175M10.7175 25.404V20.3949C10.7175 20.1977 10.5576 20.0378 10.3604 20.0378H7.40305C7.2058 20.0378 7.0459 20.1977 7.0459 20.3949V22.7209V25.404M10.7175 25.404H7.0459M5.91617 16.3662H11.5648M5.06888 12.6946H7.0459M5.06888 9.02293H7.0459M10.4351 9.02293H12.4121M12.4121 12.6946H10.4351M19.4729 15.2364H21.7324M21.7324 20.0378H19.4729"
                    stroke="#325182"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </SvgIcon>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                <Typography color="#B1B1B1" variant="subtitle1">
                  Total Sites
                </Typography>
                <Tooltip placement="right" title="Total number of Scale sites within this region">
                  <InfoOutlinedIcon color="darkGrey" />
                </Tooltip>
              </Box>
              <Typography variant="h5" color="secondary">
                {totalSites}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" mb="2em">
            <Box
              bgcolor="white"
              mr="10px"
              width="60px"
              height="60px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #E1E2E4"
              borderRadius="5px"
            >
              <SvgIcon color="secondary" fontSize="large">
                <svg
                  width="27"
                  height="28"
                  viewBox="0 0 27 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13.5" cy="14.4336" r="12.5" stroke="#325182" strokeWidth="2" />
                </svg>
              </SvgIcon>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                <Typography color="#B1B1B1" variant="subtitle1">
                  Total Capacity
                </Typography>
                <Tooltip
                  placement="right"
                  title="Total Commissioned BESS Capacity + Genset Capacity in AC"
                >
                  <InfoOutlinedIcon color="darkGrey" />
                </Tooltip>
              </Box>
              <Typography variant="h5" color="secondary">
                {totalCapacity} kW
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" mb="2em">
            <Box
              bgcolor="white"
              mr="10px"
              width="60px"
              height="60px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #E1E2E4"
              borderRadius="5px"
            >
              <SvgIcon color="secondary" fontSize="large">
                <svg
                  width="24"
                  height="16"
                  viewBox="0 0 24 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 8.36719L23 8.36719M23 8.36719L16.5 1.86719M23 8.36719L16.5 14.8672"
                    stroke="#325182"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                <Typography color="#B1B1B1" variant="subtitle1">
                  Total Output
                </Typography>
                <Tooltip placement="right" title="Total Real Time Solar + Genset + BESS Power">
                  <InfoOutlinedIcon color="darkGrey" />
                </Tooltip>
              </Box>
              <Typography variant="h5" color="secondary">
                {totalOutput} kW
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" mb="2em">
            <Box
              bgcolor="white"
              mr="10px"
              width="60px"
              height="60px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #E1E2E4"
              borderRadius="5px"
            >
              <SvgIcon color="secondary" fontSize="large">
                <svg
                  width="23"
                  height="32"
                  viewBox="0 0 23 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.72676 17.7394L16.2301 1.18586C16.5864 0.779129 17.245 1.14896 17.0833 1.66495L13.4251 13.3318C13.3241 13.6538 13.5647 13.9814 13.9022 13.9814H21.3286C21.7511 13.9814 21.9831 14.4728 21.7147 14.7991L8.63383 30.6976C8.28796 31.118 7.61449 30.7612 7.76801 30.2389L11.01 19.2099C11.1041 18.8897 10.864 18.5689 10.5303 18.5689H2.10283C1.6731 18.5689 1.44357 18.0627 1.72676 17.7394Z"
                    stroke="#325182"
                    strokeWidth="2"
                  />
                </svg>
              </SvgIcon>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                <Typography color="#B1B1B1" variant="subtitle1">
                  Stored Energy
                </Typography>
                <Tooltip
                  placement="right"
                  title="Total stored energy across all BESS within this region"
                >
                  <InfoOutlinedIcon color="darkGrey" />
                </Tooltip>
              </Box>
              <Typography variant="h5" color="secondary">
                {totalStored} kWh
              </Typography>
            </Box>
          </Box>
        </Box>
        {demoMode ? (
          <Box
            display="flex"
            flexDirection="column"
            mb="30px"
            pl="3em"
            flexGrow={1}
            maxHeight="350px"
            overflow="scroll"
          >
            {entity ? (
              <EventTable entity={entity} resources={resourceList} />
            ) : (
              <Typography
                color="secondary"
                variant="h5"
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                Please select an ISO or utility to see events
              </Typography>
            )}
          </Box>
        ) : null}
      </Box>

      <Box display="flex" flexDirection="column" mb="30px">
        <Box>
          <Typography variant="h4" color="secondary">
            Assets {" - " + (getSelectedEntity()?.name || "")}
          </Typography>
        </Box>
        <Box>
          <div className="line"></div>
        </Box>
        <Box>
          <Tabs
            value={activeDERTab}
            onChange={handleDERTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="BESS" />
            <Tab label="Solar" />
            <Tab label="Genset" />
          </Tabs>
        </Box>
        {activeDERTab === 0 ? (
          <Box>
            <AssetTable type="bess" list={resourceList?.BESS?.array || []} />
          </Box>
        ) : null}
        {activeDERTab === 1 ? (
          <Box>
            <AssetTable type="solar" list={resourceList?.Solar?.array || []} />
          </Box>
        ) : null}
        {activeDERTab === 2 ? (
          <Box>
            <AssetTable type="genset" list={resourceList?.Genset?.array || []} />
          </Box>
        ) : null}
      </Box>
      <Box height="400px"></Box>
    </Box>
  );
};

export default Markets;
