/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";

const count = (alarms) =>
  alarms.find((alarm) => alarm.criticality === "high")?.count > 0
    ? alarms.find((alarm) => alarm.criticality === "high").count
    : alarms.find((alarm) => alarm.criticality === "medium")?.count || 0;

const sum = (alarms) => {
  return 0;
};

const color = (alarms) =>
  alarms.find((alarm) => alarm.criticality === "high")?.count > 0
    ? "red"
    : alarms.find((alarm) => alarm.criticality === "medium")?.count === 0
      ? "grey"
      : "amber";

const AlarmIndicator = ({ alarms, showSum }) => {
  let alarmColor = color(alarms);
  let alarmCount = 0;
  if (!showSum) {
    alarmCount = count(alarms);
  } else {
    alarmCount = sum(alarms);
  }
  return (
    <div role={"alarm-" + color} className={"alarm " + alarmColor}>
      <span role="alarm" className="number">
        {alarmCount}
      </span>
    </div>
  );
};

AlarmIndicator.propTypes = {
  alarms: PropTypes.array,
  showSum: PropTypes.bool,
};

export { AlarmIndicator, count, color };
