import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const FallbackComponent = ({ error }) => {
  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box>There was an error in the application, please refresh the page.</Box>
      <Box>{error.message}</Box>
    </Box>
  );
};

FallbackComponent.propTypes = {
  error: PropTypes.object,
};

export default FallbackComponent;
