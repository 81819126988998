/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Box } from "@mui/material";
import SiteMap from "./SiteMap";
import { useQuery } from "@tanstack/react-query";
import { getPortfolioPowerQuery } from "../../lib/Queries";

const PortfolioMap = () => {
  const { data } = useQuery({
    ...getPortfolioPowerQuery(),
    refetchInterval: 15000,
  });

  if (data) {
    return (
      <Box flexDirection="column" ml="80px" pt="10px" pl="4em" pr="4em" alignItems="center">
        <SiteMap sites={data?.sites} />
      </Box>
    );
  }
};

export default PortfolioMap;
