/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import OrganizationSettings from "./OrganizationSettings";

const styles = {
  caption: {
    color: "rgba(0, 0, 0, 0.60)",
    marginBottom: "1rem",
  },
  settingsPanel: {
    width: "59rem",
    py: "3rem",
    px: "4rem",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "700",
  },
  genericTabLabel: {
    fontSize: 14,
  },
};

const NewOrganizationModal = ({ open, onClose, onOrganizationCreated }) => {
  const theme = useTheme();
  return (
    <Dialog maxWidth="936px" open={open} onClose={onClose}>
      <DialogContent>
        <Box sx={styles.settingsPanel}>
          <OrganizationSettings
            organization={null}
            onClose={onClose}
            onOrganizationCreated={onOrganizationCreated}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

NewOrganizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOrganizationCreated: PropTypes.func.isRequired,
};

export default NewOrganizationModal;
