/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const DeleteUserModal = ({ open, onClose, onConfirm, userName }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md" // Use maxWidth to control the width
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiPaper-root": {
          width: "48vw",
          margin: "0 64px",
          padding: "48px 64px",
          boxSizing: "border-box",
        },
      }}
    >
      <DialogTitle
        variant="h5"
        sx={{
          mb: 4,
          fontSize: "34px",
          fontWeight: 600,
        }}
      >
        Delete User
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
          <p>Are you sure you want to delete the user “{userName}”?</p>
          <p>This will delete all user data.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mt: 4 }}>
        <Button onClick={onConfirm} variant="outlined" color="error">
          Delete User
        </Button>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Don’t Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default DeleteUserModal;
