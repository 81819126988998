/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Box } from "@mui/material";

const SiteWeather = () => {
  return (
    <Box height="100px" width="100%" mb="30px">
      <a
        className="weatherwidget-io"
        href="https://forecast7.com/en/40d71n74d01/new-york/"
        data-label_1="NEW YORK"
        data-label_2="WEATHER"
        data-mode="Current"
        data-theme="pure"
      ></a>
      <script>
        {
          !(function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (!d.getElementById(id)) {
              js = d.createElement(s);
              js.id = id;
              js.src = "https://weatherwidget.io/js/widget.min.js";
              fjs.parentNode.insertBefore(js, fjs);
            }
          })(document, "script", "weatherwidget-io-js")
        }
      </script>
    </Box>
  );
};

export default SiteWeather;
