/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import { createTheme, getContrastRatio } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, Arial, Helvetica, sans-serif",
    h5: {
      fontSize: "20px",
      fontWeight: 500,
      letterSpacing: "0px",
    },
    h4: {
      fontSize: "24px",
      fontWeight: 400,
      letterSpacing: "0px",
    },
    h3: {
      fontSize: "33px",
      fontWeight: 400,
      letterSpacing: "0.25px",
    },
    h2: {
      fontSize: "47px",
      fontWeight: 300,
      letterSpacing: "0px",
    },
    h1: {
      fontSize: "59px",
      fontWeight: 300,
      letterSpacing: "0.5px",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 400,
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      letterSpacing: "0.5px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "0.25px",
    },
    overline: {
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: "0.5px",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "0.4px",
    },
    overline2: {
      fontWeight: 100,
      fontSize: "10px",
    },
  },
  palette: {
    primary: {
      main: "#3AACFF",
      contrastText: getContrastRatio("#3AACFF", "#fff") > 1 ? "#fff" : "#000",
    },
    secondary: {
      main: "#325182",
    },
    alt: {
      main: "#7B7B7B",
    },
    warning: {
      main: "#F3A944",
    },
    error: {
      main: "#FF5A5A",
    },
    power: {
      main: "#78BB39",
    },
    bess: {
      main: "#57BCDC",
    },
    solar: {
      light: "#ECFFDB",
      main: "#A6E56A",
      contrastText: "#82C341",
    },
    grey: {
      light: "#F8FAFB",
      main: "#E4E4E4",
      dark: "#B1B1B1",
    },
    darkGrey: {
      light: "#B1B1B1",
      main: "#B1B1B1",
      dark: "#B1B1B1",
    },
    lightGrey: {
      light: "#F8FAFB",
      main: "#F8FAFB",
      dark: "#F8FAFB",
      contrastText: "#A4A4A4",
    },
    success: {
      light: "#ECFFDB",
      main: "#ECFFDB",
      contrastText: "#82C341",
    },
    textIcon: {
      main: "#636B74",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
        select: {
          fontStyle: "normal",
          fontSize: "small",
        },
        menu: {
          fontStyle: "normal",
          fontSize: "small",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          border: "1px white solid",
          color: "white",
          boxShadow: "1.25px 1.25px 5px rgba(0, 0, 0, 0.12)",
          transform: "scale(0.9) translate(35%, -35%)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none", // Remove the box shadow
          "&:hover": {
            boxShadow: "none", // Remove the box shadow on hover
          },
          "&:active": {
            boxShadow: "none", // Remove the box shadow on active
          },
        },
        sizeLarge: {
          height: "40px",
          paddingLeft: "34px",
          paddingRight: "34px",
        },
        sizeSmall: {
          height: "32px",
          paddingLeft: "14px",
          paddingRight: "14px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "400",
          fontSize: "16px",
          color: "#868686",
        },
        root: {
          borderBottom: "0px solid #E1E2E4",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#F8FAFB",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          fontWeight: 500,
          letterSpacing: "0px",
          color: "#868686",
          "&.Mui-selected": {
            color: "#325182",
          },
          textTransform: "none",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
        columnHeader: {
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-sortIcon": {
            color: "inherit",
          },
          "&:active .MuiDataGrid-sortIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
  classes: {
    customTooltipContainer: {
      backgroundColor: "#fff",
      borderRadius: "5px",
      border: "1px solid #E1E2E4",
      width: "240px",
      padding: "1.5rem",
    },
    alarmDetailContainer: {
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
    },
    point: {
      width: "8px",
      height: "8px",
      verticalAlign: "middle",
      borderRadius: "35px !important",
    },
    pointHollow: {
      width: "8px",
      height: "8px",
      verticalAlign: "middle",
      borderRadius: "8px !important",
      border: "2px solid",
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginBottom: "1.5rem",
    },
    tableContainer: {
      maxWidth: "100%",
      marginBottom: "1rem",
    },
    genericPanel: {
      width: "100%",
      backgroundColor: "#fff",
      border: "1px solid #E1E2E4",
      borderRadius: "5px",
      padding: "1.5rem",
      paddingRight: "1.5rem",
      minHeight: "150px",
    },
    pageContainer: {
      display: "flex",
      justifyContent: "center",
      marginLeft: "80px",
      width: "calc(100% - 80px)",
    },
    page: {
      flexDirection: "column",
      paddingTop: "4rem",
      paddingLeft: "5rem",
      paddingRight: "5rem",
      width: "100%",
      maxWidth: "1400px",
      marginBottom: "5rem",
    },
  },
});

theme.recharts = {
  valueColors: ["#85B953", "#325182", "#57BCDC", "#C6C6C6", "#F3A944", "#FF5A5A", "#A6E56A"],
};

export default theme;
