const DemoData = {};

DemoData.sites = [
  {
    name: "Albertsons - Hercules",
    uuid: "2c6eecc1-1da4-4ffb-b2ca-ebb03ef1d570",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-122.33740418487491",
    latitude: "37.91967373035351",
  },
  {
    name: "Amond World 2",
    uuid: "de21775c-43f7-459e-a1ea-70c00e681e2e",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-120.120082",
    latitude: "37.003291",
  },
  {
    name: "SMWD - Ranch Water Filtration Plant",
    uuid: "841ffb18-09ad-4681-ba81-55afe5bdce54",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-117.633185",
    latitude: "33.581397",
  },
  {
    name: "SMWD - Chiquita Plant",
    uuid: "13937315-daed-4908-ac2e-b68d941ef0c6",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-117.608191",
    latitude: "33.541827",
  },
  {
    name: "Trinity Packing",
    uuid: "d14ee793-4653-4333-b3ac-1b7327dbe64f",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-119.45071033972751",
    latitude: "36.619147497208296",
  },
  {
    name: "Goleta Water District",
    uuid: "8588c44d-14ba-4e33-af2c-81edbe2351ad",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-119.8690285345668",
    latitude: "34.47106992879209",
  },
  {
    name: "Soboba Casino",
    uuid: "e875ae0e-6603-4ed4-b199-1c05dd6d50eb",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-116.93048529440482",
    latitude: "33.79160914052536",
  },
  {
    name: "Cal Am Water - Ventura",
    uuid: "52324e26-a6b1-449b-96f8-0e7e8a31b2c9",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-118.928928",
    latitude: "34.187046",
  },
  {
    name: "QCD - InCharge - La Puente Site",
    uuid: "d6fafc7c-17de-4c19-b244-8b73952e34ad",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-117.98782156370845",
    latitude: "34.030316733169016",
  },
  {
    name: "Valley Transit Authority",
    uuid: "591f13d1-c9b3-469a-b777-5a76ac45f800",
    "RSO/ISO": "d9a42046-f6ee-4e71-b4c8-07802740720f",
    longitude: "-121.892026",
    latitude: "37.335653",
  },
];
DemoData.resources = {
  "d9a42046-f6ee-4e71-b4c8-07802740720f": {
    BESS: {
      array: [
        {
          name: "BESS",
          uuid: "e8fd713d-be39-4a41-bb74-4073b381d10b",
          site_name: "Albertsons - Hercules",
          site_uuid: "2c6eecc1-1da4-4ffb-b2ca-ebb03ef1d570",
          power_capacity: 150,
          energy_capacity: 556,
          soc: 85,
          power: 0,
        },
        {
          name: "BESS",
          uuid: "54e8481f-67f1-4ed8-9c93-b4921303eb0a",
          site_uuid: "d6fafc7c-17de-4c19-b244-8b73952e34ad",
          site_name: "QCD - InCharge - La Puente Site",
          power_capacity: 1124,
          energy_capacity: 2248,
          soc: 80,
          power: 0,
        },
        {
          name: "BESS",
          uuid: "4b899aed-eeb0-4a1a-8f9e-189a007a442e",
          site_uuid: "e875ae0e-6603-4ed4-b199-1c05dd6d50eb",
          site_name: "Soboba Casino",
          power_capacity: 1550,
          energy_capacity: 6200,
          soc: 83,
          power: 0,
        },
        {
          name: "BESS",
          uuid: "03ee993a-8656-4706-84e4-baf873250191",
          site_uuid: "d14ee793-4653-4333-b3ac-1b7327dbe64f",
          site_name: "Trinity Packing",
          power_capacity: 1027,
          energy_capacity: 2145,
          soc: 77,
          power: 0,
        },
        {
          name: "BESS",
          uuid: "934b7b80-a8e9-463a-9fd1-c81f4199e4c0",
          site_uuid: "52324e26-a6b1-449b-96f8-0e7e8a31b2c9",
          site_name: "Cal Am Water - Ventura",
          power_capacity: 250,
          energy_capacity: 500,
          soc: 79,
          power: 0,
        },
        {
          name: "BESS_1",
          uuid: "7ea9019e-7f05-4ee2-b152-3109effb4f9f",
          site_uuid: "8588c44d-14ba-4e33-af2c-81edbe2351ad",
          site_name: "Goleta Water District",
          power_capacity: 125,
          energy_capacity: 502,
          soc: 88,
          power: 0,
        },
        {
          name: "BESS_2",
          uuid: "f4613317-de9e-405f-8e69-0f405974ef99",
          site_uuid: "8588c44d-14ba-4e33-af2c-81edbe2351ad",
          site_name: "Goleta Water District",
          power_capacity: 125,
          energy_capacity: 502,
          soc: 86,
          power: 0,
        },
        {
          name: "BESS_1",
          uuid: "003b248a-3fbe-4716-810c-902bedc2f363",
          site_uuid: "841ffb18-09ad-4681-ba81-55afe5bdce54",
          site_name: "SMWD - Ranch Water Filtration Plant",
          power_capacity: 767,
          energy_capacity: 3070,
          soc: 88,
          power: 0,
        },
        {
          name: "BESS_2",
          uuid: "f9656066-5395-40be-9a75-6004d684ed82",
          site_uuid: "841ffb18-09ad-4681-ba81-55afe5bdce54",
          site_name: "SMWD - Ranch Water Filtration Plant",
          power_capacity: 767,
          energy_capacity: 3070,
          soc: 86,
          power: 0,
        },
        {
          name: "BESS_1",
          uuid: "13b964b7-91e0-45b9-982a-1d1cd9cc5b85",
          site_uuid: "13937315-daed-4908-ac2e-b68d941ef0c6",
          site_name: "SMWD - Chiquita Plant",
          power_capacity: 767,
          energy_capacity: 3070,
          soc: 88,
          power: 0,
        },
        {
          name: "BESS_2",
          uuid: "d36b1de2-27b0-4eda-98f4-ee468724ff0c",
          site_uuid: "13937315-daed-4908-ac2e-b68d941ef0c6",
          site_name: "SMWD - Chiquita Plant",
          power_capacity: 767,
          energy_capacity: 3070,
          soc: 86,
          power: 0,
        },
        {
          name: "BESS",
          uuid: "9c001e4f-496f-4804-9fb4-bce42a7b178d",
          site_uuid: "bd45cf98-a4fa-4134-9f52-3f28b4947b71",
          site_name: "Valley Transit Authority",
          power_capacity: 326,
          energy_capacity: 1305,
          soc: 86,
          power: 0,
        },
      ],
    },
    Solar: {
      array: [
        {
          name: "Solar",
          uuid: "cbdd850f-6f87-49d6-b943-97c19e8d6d00",
          site_name: "Albertsons - Hercules",
          site_uuid: "2c6eecc1-1da4-4ffb-b2ca-ebb03ef1d570",
          power_capacity: 660,
          power: null,
        },
        {
          name: "Solar",
          uuid: "866db1a0-b7f6-442d-8f21-19f98c2daa87",
          site_name: "QCD - InCharge - La Puente Site",
          site_uuid: "2c6eecc1-1da4-4ffb-b2ca-ebb03ef1d570",
          power_capacity: 1300,
          power: null,
        },
        {
          name: "Solar",
          uuid: "b8d1f5b2-7a6d-4ffe-9dc7-44ba07ccec2a",
          site_uuid: "e875ae0e-6603-4ed4-b199-1c05dd6d50eb",
          site_name: "Soboba Casino",
          power_capacity: 1500,
          power: null,
        },
        {
          name: "Solar",
          uuid: "b8d8aec1-0196-47d2-8a26-aae33ea5b383",
          site_uuid: "d14ee793-4653-4333-b3ac-1b7327dbe64f",
          site_name: "Trinity Packing",
          power_capacity: 491,
          power: null,
        },
        {
          name: "Solar",
          uuid: "b8d8aec1-0196-47d2-8a26-aae33ea5b383",
          site_uuid: "bd45cf98-a4fa-4134-9f52-3f28b4947b71",
          site_name: "Valley Transit Authority",
          power_capacity: 450,
          power: null,
        },
      ],
    },
    Genset: {
      array: [
        {
          name: "Genset",
          uuid: "82cda8fd-cb64-4383-88db-90f56ffec7d0",
          site_name: "Albertsons - Hercules",
          site_uuid: "2c6eecc1-1da4-4ffb-b2ca-ebb03ef1d570",
          power_capacity: 235,
          power: 0,
        },
        {
          name: "Genset",
          uuid: "d6fafc7c-17de-4c19-b244-8b73952e34ad",
          site_name: "QCD - InCharge - La Puente Site",
          site_uuid: "2c6eecc1-1da4-4ffb-b2ca-ebb03ef1d570",
          power_capacity: 1500,
          power: 0,
        },
        {
          name: "Genset",
          uuid: "f7789cd8-c213-4412-847d-43441d4be3a9",
          site_uuid: "d14ee793-4653-4333-b3ac-1b7327dbe64f",
          site_name: "Trinity Packing",
          power_capacity: 1200,
          power: 0,
        },
      ],
    },
  },
};

/**
 * BESS energy for an event is nameplate * 0.40
 * ELRP = 2$/kWh
 * PDR = 0.06$/kWh
 */
DemoData.events = {
  "d9a42046-f6ee-4e71-b4c8-07802740720f": [
    {
      id: "ID097",
      program: "ELRP",
      start: new Date("2024-03-06T12:00:00"),
      stop: new Date("2024-03-06T15:00:00"),
    },
    {
      id: "ID098",
      program: "PDR",
      start: new Date("2024-03-05T12:00:00"),
      stop: new Date("2024-03-05T16:00:00"),
    },
    {
      id: "ID099",
      program: "PDR",
      start: new Date("2024-02-18T12:00:00"),
      stop: new Date("2024-02-18T16:00:00"),
    },
    {
      id: "ID100",
      program: "ELRP",
      start: new Date("2024-02-18T12:00:00"),
      stop: new Date("2024-02-18T15:00:00"),
    },
    {
      id: "ID101",
      program: "PDR",
      start: new Date("2024-02-18T12:00:00"),
      stop: new Date("2024-02-18T16:00:00"),
    },
  ],
};

DemoData.revenue = {
  ELRP: 2,
  PDR: 0.06,
};

DemoData.getBessPercent = () => {
  return Math.round((Math.random() * (0.7 - 0.3) + 0.3) * 100) / 100;
};

DemoData.bessRange = {
  min: 0.2,
  max: 0.8,
};

export default DemoData;
