import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
} from "@mui/material";

import SiteLink from "../Shared/SiteLink";

import PropTypes from "prop-types";

import { useFlags } from "launchdarkly-react-client-sdk";

import { useSearchParams } from "react-router-dom";

const AssetTable = ({ type, list }) => {
  const flags = useFlags();
  const [searchParams] = useSearchParams();
  const demoMode = flags.portfolioDemoMode || searchParams.get("mode") === "demo";

  const calculateTotalPowerCapacity = () => {
    let cap = 0;
    list.forEach((item) => {
      cap = cap + (item.power_capacity || 0);
    });
    return Math.round(cap);
  };

  const calculateTotalEnergyCapacity = () => {
    let cap = 0;
    list.forEach((item) => {
      cap = cap + (item.energy_capacity || 0);
    });
    return Math.round(cap);
  };

  const calculateTotalStored = () => {
    let stored = 0;
    list.forEach((item) => {
      stored = stored + (item.energy_capacity || 0) * (item.soc || 0) * 0.01;
    });
    return Math.round(stored);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Asset</TableCell>
            <TableCell align="right">Site</TableCell>
            <TableCell align="right">Nameplate Capacity</TableCell>
            {type === "bess" ? <TableCell align="right">Stored Energy (SOC%)</TableCell> : null}
            {demoMode ? <TableCell align="right">Programs</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" color="secondary">
                Totals
              </Typography>
            </TableCell>
            <TableCell></TableCell>

            {type === "bess" ? (
              <>
                <TableCell align="right">
                  <Typography variant="subtitle1" color="secondary">
                    {calculateTotalPowerCapacity() + "kW / "}
                    {calculateTotalEnergyCapacity() + "kWh"}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" color="secondary">
                    {calculateTotalStored()} kWh
                  </Typography>
                </TableCell>
              </>
            ) : (
              <TableCell align="right">
                <Typography variant="subtitle1" color="secondary">
                  {calculateTotalPowerCapacity() + " kW"}
                </Typography>
              </TableCell>
            )}
            {demoMode ? <TableCell></TableCell> : null}
          </TableRow>
          {list.map((item) => (
            <TableRow key={item.uuid} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell scope="row">
                <Typography color="grey">{item.name}</Typography>
              </TableCell>
              <TableCell align="right">
                <SiteLink
                  variant="subtitle1"
                  color="grey"
                  site={{ name: item.site_name, uuid: item.site_uuid }}
                />
              </TableCell>
              {type === "bess" ? (
                <TableCell align="right">
                  <Typography color="grey">
                    {item.power_capacity}kW / {item.energy_capacity}kWh
                  </Typography>
                </TableCell>
              ) : (
                <TableCell align="right">
                  <Typography color="grey">
                    {isNaN(Math.round(item.power_capacity))
                      ? " N/A "
                      : Math.round(item.power_capacity)}
                    kW
                  </Typography>
                </TableCell>
              )}

              {type === "bess" ? (
                <TableCell align="right">
                  <Typography color="grey">
                    {isNaN(Math.round(item.energy_capacity))
                      ? " N/A "
                      : Math.round((item.energy_capacity || 0) * (item.soc * 0.01))}
                    kWh ({item.soc}%)
                  </Typography>
                </TableCell>
              ) : null}
              {demoMode ? (
                <TableCell align="right">
                  {type === "bess" ? (
                    <>
                      <Chip color="lightGrey" label="PDR" />
                      <Chip color="lightGrey" label="ELRP" />
                    </>
                  ) : null}
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AssetTable.propTypes = {
  type: PropTypes.string,
  list: PropTypes.array,
  synthetic: PropTypes.bool,
};

export default AssetTable;
