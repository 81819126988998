/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import { Box, Typography } from "@mui/material";
import AlarmBadge from "../Common/AlarmBadge";
import PropTypes from "prop-types";
import { decorateAlarms } from "../../../lib/Util";

const OtherAlarms = ({ alarms, timezone, assemblies }) => {
  // decorate the alarms with assembly data, if available
  decorateAlarms(alarms.alarms, assemblies);

  const styles = {
    badge: {
      position: "absolute",
      top: "-16px",
      right: "-20px",
    },
    otherSystemsContainer: {
      position: "absolute",
      bottom: "12px",
      left: "20px",
    },
  };

  return (
    <Box sx={styles.otherSystemsContainer}>
      <Box sx={styles.badge}>
        <AlarmBadge
          alarmsCount={alarms.alarms_count}
          alarms={alarms.alarms}
          timezone={timezone}
          source="Other Systems"
          hasAssets={false}
        />
      </Box>
      <Typography
        variant="subtitle1"
        color="grey"
        sx={{ display: "inline-block", pointerEvents: "none" }}
      >
        Other Systems
      </Typography>
    </Box>
  );
};

OtherAlarms.propTypes = {
  alarms: PropTypes.object,
  timezone: PropTypes.string,
  assemblies: PropTypes.array,
};

export default OtherAlarms;
