/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState } from "react";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";

/**
 * AlertBox is a reusable component for displaying an alert message with different severity types.
 *
 * @param {string} type - the severity type of the alert box (i.e. 'success', 'info', 'warning', 'error')
 * @param {string} message - the message to be displayed inside the alert box
 * @param {boolean} openAlert - Hide and Show the AlertBox
 * @returns {JSX.Element} - the AlertBox component with the given type and message
 */
const AlertBox = ({ type, message, openAlert, clearAlert }) => {
  const [open, setOpen] = useState(openAlert);

  const handleClose = () => {
    setOpen(false);
    clearAlert();
  };

  return (
    <Collapse in={open} role="alert-collapsible">
      <Alert
        className={`banner banner-${type}`}
        onClose={() => {}}
        severity={type}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Collapse>
  );
};

AlertBox.propTypes = {
  type: PropTypes.oneOf(["success", "info", "warning", "error"]).isRequired,
  message: PropTypes.string.isRequired,
  openAlert: PropTypes.bool.isRequired,
  clearAlert: PropTypes.func.isRequired,
};

export default AlertBox;
