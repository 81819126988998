import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import SiteEquipmentList from "./SiteEquipmentList";
import PropTypes from "prop-types";
import TimeSeriesLineGraph from "../Graphs/Common/TimeSeriesLineGraph";
import theme from "./../../style/theme";
import { useTheme } from "@mui/material/styles";
const SiteEquipmentDetail = ({ site }) => {
  const theme = useTheme();
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  /** Remove metrics that don't have units */
  const getProcessedAssemblies = () => {
    const processedAssemblies = site.assemblies.map((assembly) => {
      const metricsWithUnit = assembly.time_series.filter((metric) => metric.unit);
      assembly.time_series = metricsWithUnit;
      return assembly;
    });
    return processedAssemblies;
  };

  return (
    <Box display="flex" flexDirection="column" sx={theme.classes.genericPanel}>
      <Box>
        <TimeSeriesLineGraph site={site} metricUuids={selectedMetrics} />
      </Box>
      <Box mt="2em">
        <SiteEquipmentList
          assemblies={getProcessedAssemblies() || []}
          updateSelectedMetrics={setSelectedMetrics}
        />
      </Box>
    </Box>
  );
};

SiteEquipmentDetail.propTypes = {
  site: PropTypes.object,
};

export default SiteEquipmentDetail;
