/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

// This login page redirects to Auth0.

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

const Login = ({ setAuthData }) => {
  const { loginWithRedirect } = useAuth0();

  const prevPath = window.location.pathname;
  const prevSearch = window.location.search;
  const redirectURL = prevPath + prevSearch;

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: redirectURL,
      },
    });
  };

  useEffect(() => {
    handleLogin();
  }, [loginWithRedirect]);

  return null;
};

Login.propTypes = {
  setAuthData: PropTypes.func,
};

export default Login;
