/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AlarmSummary = ({ alarms }) => {
  const theme = useTheme();

  // calculate number of red resolved, amber resolved, red active and amber active
  let redResolvedCount = 0,
    amberResolvedCount = 0,
    redActiveCount = 0,
    amberActiveCount = 0;

  alarms.forEach((alarm) => {
    let severity = alarm.alarm_type.toLowerCase();
    let resolved = Boolean(alarm.stop_time);
    if (severity === "red" && resolved) redResolvedCount++;
    if (severity === "amber" && resolved) amberResolvedCount++;
    if (severity === "red" && !resolved) redActiveCount++;
    if (severity === "amber" && !resolved) amberActiveCount++;
  });

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {redActiveCount ? (
        <Box display="flex" flexDirection="row" alignItems="baseline" mt="0.25em" mb="0.25em">
          <Box sx={{ background: theme.palette.error.main, ...theme.classes.point }}></Box>
          <Box pl="0.5em">
            <Typography variant="caption">{redActiveCount} Alarms</Typography>
          </Box>
        </Box>
      ) : null}
      {amberActiveCount ? (
        <Box display="flex" flexDirection="row" alignItems="baseline" mt="0.25em" mb="0.25em">
          <Box sx={{ background: theme.palette.warning.main, ...theme.classes.point }}></Box>
          <Box pl="0.5em">
            <Typography variant="caption">{amberActiveCount} Alarms</Typography>
          </Box>
        </Box>
      ) : null}
      {redResolvedCount ? (
        <Box display="flex" flexDirection="row" alignItems="baseline" mt="0.25em" mb="0.25em">
          <Box
            sx={{
              borderColor: theme.palette.error.main + "!important",
              ...theme.classes.pointHollow,
            }}
          ></Box>
          <Box pl="0.5em">
            <Typography variant="caption">{redResolvedCount} Alarms</Typography>
          </Box>
        </Box>
      ) : null}
      {amberResolvedCount ? (
        <Box display="flex" flexDirection="row" alignItems="baseline" mt="0.25em" mb="0.25em">
          <Box
            sx={{
              borderColor: theme.palette.warning.main + "!important",
              ...theme.classes.pointHollow,
            }}
          ></Box>
          <Box pl="0.5em">
            <Typography variant="caption">{amberResolvedCount} Alarms</Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

AlarmSummary.propTypes = {
  alarms: PropTypes.array,
};

export default AlarmSummary;
