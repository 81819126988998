/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { getCurrentTimeInTimezone, toISOStringWithTimezone } from "../../../lib/Util";
import { CSVLink } from "react-csv";
import { getAlarmHistory } from "../../../lib/Queries";
import { LoadingButton } from "@mui/lab";

const headers = [
  { label: "Severity", key: "alarm_type" },
  { label: "Start Time", key: "timestamp" },
  { label: "Resolved Time", key: "stop_time" },
  { label: "Alarm Name", key: "description" },
  { label: "Asset", key: "type" },
  { label: "Site Name", key: "site_name" },
];

const addSiteName = (alarms, siteName) => {
  return alarms.map((alarm) => {
    return { ...alarm, site_name: siteName };
  });
};

const AlarmsDownloadDialog = ({ start, stop, open, onClose, siteUuid, siteName, timezone }) => {
  const CURRENT_GRAPH = "current-graph";
  const ALL_HISTORY = "all-history";
  const [interval, setInterval] = useState(CURRENT_GRAPH);
  const [requestStart, setRequestStart] = useState(start);
  const [requestStop, setRequestStop] = useState(stop);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = () => {
    setInterval(CURRENT_GRAPH);
    setData(null);
    onClose();
  };

  const CSVDownload = (props) => {
    const btnRef = useRef(null);
    useEffect(() => {
      btnRef.current?.click();
      handleClose();
    }, [btnRef]);
    return (
      <CSVLink {...props}>
        <span ref={btnRef} />
      </CSVLink>
    );
  };

  const getHistoricalStart = () => {
    const startDate = "2010-01-01T00:00:00";
    const historicalStartTimezoneFormatted = toISOStringWithTimezone(new Date(startDate), timezone);
    return historicalStartTimezoneFormatted;
  };

  const getHistoricalStop = () => {
    const historicalStop = getCurrentTimeInTimezone(timezone);
    return historicalStop;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAlarmHistory(siteUuid, requestStart, requestStop);
      const alarms = response["historical_alerts"];
      const alarmsWithSiteName = addSiteName(alarms, siteName);
      setData(alarmsWithSiteName);
    } catch (error) {
      console.error("Error downloading alarms data: ", error);
    }
    setLoading(false);
  };

  const handleStartStopChange = () => {
    if (interval === ALL_HISTORY) {
      setRequestStart(getHistoricalStart());
      setRequestStop(getHistoricalStop());
    } else {
      setRequestStart(start);
      setRequestStop(stop);
    }
  };

  useEffect(() => {
    handleStartStopChange();
  }, [interval, start, stop]);

  return (
    <Dialog open={open}>
      <Box sx={{ px: "2.25rem", py: "2.5rem" }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Download Alarms Data
        </Typography>
        <Typography variant="body2" sx={{ mb: 5 }}>
          Time Range: {new Date(requestStart).toLocaleString()} to{" "}
          {new Date(requestStop).toLocaleString()}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="interval-select-id">Time Range</InputLabel>
          <Select
            value={interval}
            label="Time Range"
            onChange={(ev) => setInterval(ev.target.value)}
          >
            <MenuItem value={CURRENT_GRAPH}>As shown on graph</MenuItem>
            <MenuItem value={ALL_HISTORY}>All historical alarms for this site</MenuItem>
          </Select>
        </FormControl>
        <Box pt="2em" display="flex" flexDirection="row" width="100%" justifyContent="end">
          <Button
            onClick={handleClose}
            variant="outline"
            sx={{ marginLeft: "1em", width: "150px" }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={fetchData}
            variant="contained"
            sx={{ marginLeft: "1em", width: "150px" }}
          >
            Download CSV
          </LoadingButton>
        </Box>
        {data ? (
          <CSVDownload
            data={data}
            target="_blank"
            filename={`scaleos-alarms-export-${siteName}-${new Date().toISOString()}.csv`}
            headers={headers}
          />
        ) : null}
      </Box>
    </Dialog>
  );
};

AlarmsDownloadDialog.propTypes = {
  start: PropTypes.string,
  stop: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  siteUuid: PropTypes.string,
  siteName: PropTypes.string,
  timezone: PropTypes.string,
};
export default AlarmsDownloadDialog;
