import React, { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  MenuList,
  MenuItem,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { OrderedSet } from "immutable";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SiteEquipmentList = ({ assemblies, updateSelectedMetrics }) => {
  const theme = useTheme();

  const [selectedAssembly, setSelectedAssembly] = useState(assemblies[0] || null);
  const [selectedMetricUuids, setSelectedMetricUuids] = useState([]);
  const [activeAssemblies, setActiveAssemblies] = useState([]);

  const addActiveAssembly = () => {
    const updateActiveAssemblies = [...activeAssemblies, selectedAssembly.uuid];
    setActiveAssemblies(updateActiveAssemblies);
  };

  const removeActiveAssembly = () => {
    const updateActiveAssemblies = activeAssemblies.filter(
      (item) => item !== selectedAssembly.uuid,
    );
    setActiveAssemblies(updateActiveAssemblies);
  };

  const modifySelectedMetrics = (checked, metricUuid) => {
    if (checked) {
      const updatedSelectedMetricUuids = [...selectedMetricUuids, metricUuid];
      bulkModifySelectedMetrics(updatedSelectedMetricUuids);
    } else {
      const updatedSelectedMetricUuids = selectedMetricUuids.filter((item) => item !== metricUuid);
      bulkModifySelectedMetrics(updatedSelectedMetricUuids);
    }
  };

  const getSelectedMetrics = () => {
    const selectedMetrics = selectedMetricUuids.map((uuid) => {
      return selectedAssembly.time_series.find((metric) => metric.uuid === uuid);
    });
    const selectedMetricsNoUndefined = selectedMetrics.filter((metric) => metric !== undefined);
    return selectedMetricsNoUndefined;
  };

  const bulkModifySelectedMetrics = (updatedSelectedMetricUuids) => {
    const updatedAssemblySelectedMetrics = new OrderedSet(updatedSelectedMetricUuids);
    const oldSelectedMetrics = new OrderedSet(selectedMetricUuids);
    const selectedAssemblyAllMetrics = new OrderedSet(
      selectedAssembly.time_series.map((metric) => metric.uuid),
    );
    const toRemoveMetrics = selectedAssemblyAllMetrics.subtract(updatedAssemblySelectedMetrics);
    let updatedSelectedMetrics = oldSelectedMetrics.subtract(toRemoveMetrics);
    updatedSelectedMetrics = updatedSelectedMetrics.union(updatedAssemblySelectedMetrics);

    if (updatedAssemblySelectedMetrics.size === 0) {
      removeActiveAssembly();
    } else {
      addActiveAssembly();
    }

    setSelectedMetricUuids([...updatedSelectedMetrics]);
    updateSelectedMetrics([...updatedSelectedMetrics]);
  };

  const clearMetrics = () => {
    setSelectedMetricUuids([]);
    setActiveAssemblies([]);
    updateSelectedMetrics([]);
  };

  const styles = {
    noPaddingButton: {
      padding: 0,
      minWidth: "auto",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  };

  const getLabel = (item) => {
    const unitPart = item.unit ? `(${item.unit})` : "";
    return `${item.name} ${unitPart}`;
  };

  const getMetricsList = () => {
    let metricsList = [];
    const timeSeries = selectedAssembly?.time_series;

    const sortedTimeseries = [...timeSeries]?.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    sortedTimeseries.forEach((series) => {
      metricsList.push(
        <Grid xs={5} md={4} key={series.uuid} item={true} sx={{ padding: "0 !important" }}>
          <Typography variant="overline" color="grey">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>
                <Checkbox
                  checked={selectedMetricUuids.includes(series.uuid)}
                  value={series.uuid}
                  onChange={(ev) => modifySelectedMetrics(ev.target.checked, ev.target.value)}
                  size="small"
                />
              </Box>
              <Box>{getLabel(series)}</Box>
            </Box>
          </Typography>
        </Grid>,
      );
    });

    return metricsList;
  };

  const getAssemblyList = () => {
    let assemblyList = [];
    assemblies.forEach((assembly) => {
      assemblyList.push(
        <MenuItem
          id={assembly.uuid}
          mb="1em"
          key={assembly.uuid}
          width="100%"
          selected={selectedAssembly.uuid === assembly.uuid}
          onClick={() => setSelectedAssembly(assembly)}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="caption" color="grey" noWrap>
              {assembly.name + " (" + assembly.source_info[0].source + ")"}
            </Typography>
            {activeAssemblies.indexOf(assembly.uuid) > -1 ? (
              <span
                className={`tooltip-color`}
                style={{ backgroundColor: theme.palette.primary.main }}
              ></span>
            ) : null}
          </Box>
        </MenuItem>,
      );
    });
    return assemblyList;
  };

  const handleSelectorChange = (event, value) => {
    const newMetricUuids = value.map((metric) => metric.uuid);
    bulkModifySelectedMetrics(newMetricUuids);
  };

  const getAssemblySelector = () => {
    return (
      <Autocomplete
        multiple
        options={selectedAssembly.time_series}
        value={getSelectedMetrics()}
        disableCloseOnSelect
        getOptionLabel={getLabel}
        onChange={handleSelectorChange}
        renderOption={(props, option, { selected }) => {
          // eslint-disable-next-line react/prop-types
          const { key, ...optionProps } = props;
          return (
            <li key={option.uuid} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {getLabel(option)}
            </li>
          );
        }}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
              {
                name: "preventOverflow",
                enabled: false,
              },
            ],
          },
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Data Series" />}
      />
    );
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box
        display="flex"
        flexDirection="column"
        sx={{ borderRight: "1px solid black" }}
        minWidth="25%"
      >
        <Box mb="1em">
          <Typography variant="body2" color="secondary">
            Hardware
          </Typography>
          <Button size="small" variant="text" onClick={clearMetrics} sx={styles.noPaddingButton}>
            Deselect All
          </Button>
        </Box>
        <MenuList>{getAssemblyList()}</MenuList>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box pl="2em" width="100%">
          {getAssemblySelector()}
          <Box mb="2em">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            ></Box>
          </Box>
          <Box>
            <Grid container spacing={0} id="metrics-list-grid" sx={{ ml: "-10px !important" }}>
              {getMetricsList()}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SiteEquipmentList.propTypes = {
  assemblies: PropTypes.array,
  updateSelectedMetrics: PropTypes.func,
};

export default SiteEquipmentList;
