/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useEffect } from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { Button, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getOrganizationsListQuery, getOrganizationUsersListQuery } from "../../lib/Queries";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loading from "../Shared/Loading";
import OrganizationLink from "../Shared/OrganizationLink";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import NewOrganizationModal from "./NewOrganizationModal";

const styles = {
  searchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "288px",
    height: "36px",
  },
  buttonContainer: {
    marginRight: "20px",
    width: "184px",
  },
  searchInput: {
    paddingLeft: "12px",
    fontSize: 16,
    height: 40,
  },
};

const fetchOrganizationUsers = async (queryClient, customers) => {
  const organizationsWithUsers = await Promise.all(
    customers.map(async (customer) => {
      const usersList = await queryClient.fetchQuery(getOrganizationUsersListQuery(customer.uuid));
      return {
        ...customer,
        userCount: usersList.users ? usersList.users.length : 0,
        userList: usersList,
      };
    }),
  );
  return organizationsWithUsers;
};

export default function OrganizationsTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [isNewOrganizationModalOpen, setIsNewOrganizationModalOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const {
    data: organizationsList,
    isLoading: organizationsListIsLoading,
    refetch: refetchOrganizationList,
  } = useQuery(getOrganizationsListQuery());

  const { data: organizationsWithUsers = [], isLoading: isLoadingUsers } = useQuery(
    ["organizationsWithUsers", organizationsList],
    () => fetchOrganizationUsers(queryClient, organizationsList.customers),
    {
      enabled: !!organizationsList && !organizationsListIsLoading,
    },
  );

  const isLoading = organizationsListIsLoading || isLoadingUsers;

  const rows = !isLoading
    ? organizationsWithUsers.map((customer) => ({
        id: customer.uuid,
        name: customer.name,
        uuid: customer.uuid,
        address: customer.address,
        siteCount: customer.sites && customer.sites.length > 0 ? customer.sites.length : "-",
        userCount: customer?.userCount > 0 ? customer.userCount : "-",
        usersList: customer.userList,
      }))
    : [];

  const getSortedRows = (rows, searchTerm) => {
    let filteredRows = rows.filter((row) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    filteredRows.sort((a, b) => {
      if (a.name.toLowerCase() === "scale") return -1; // Place "Scale" name on top
      if (b.name.toLowerCase() === "scale") return 1; // Place "Scale" name on top
      return a.name.localeCompare(b.name); // Default ascending order for other names
    });

    return filteredRows;
  };

  const filteredRows = getSortedRows(rows, searchTerm);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (params) => {
    const organization = params.row;
    navigate(`/organization/${organization.name.replace(/ /g, "-")}`, {
      state: { organization },
    });
  };

  const columns = [
    { field: "name", headerName: "Organization", flex: 1 },
    { field: "siteCount", headerName: "Sites", flex: 1 },
    { field: "userCount", headerName: "Users", flex: 1 },
    {
      field: "action",
      headerName: "",
      flex: 1,
      align: "right",
      sortable: false,
      renderCell: (params) => <OrganizationLink organization={params.row} />,
    },
  ];

  const handleNew = () => {
    setIsNewOrganizationModalOpen(true); // Open the new organization modal
  };

  const handleNewOrganizationCreated = () => {
    queryClient.invalidateQueries(getOrganizationsListQuery().queryKey);
    refetchOrganizationList();
  };

  return (
    <>
      <div style={theme.classes.container}>
        <div style={styles.buttonContainer}>
          <Button variant="contained" sx={{ width: "100%" }} onClick={handleNew}>
            New Organization
          </Button>
          <NewOrganizationModal
            open={isNewOrganizationModalOpen}
            onClose={() => setIsNewOrganizationModalOpen(false)}
            onOrganizationCreated={handleNewOrganizationCreated}
          />
        </div>
        <div style={styles.searchContainer}>
          <TextField
            id="search"
            label=""
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search organizations"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: styles.searchInput,
            }}
            sx={{
              fontSize: 16,
            }}
          />
        </div>
      </div>
      {!isLoading && (
        <div style={theme.classes.tableContainer}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            autoHeight
            autoWidth
            disableSelectionOnClick
            sortingOrder={["asc", "desc"]}
            onRowClick={handleRowClick}
            hideFooter
            sx={{
              "&, .MuiDataGrid-columnHeader": {
                borderTop: "none",
              },
              "&, [class^=MuiDataGrid]": {
                overflowX: "hidden",
                borderLeft: "none",
                borderRight: "none",
              },
            }}
          />
        </div>
      )}
      {isLoading ? <Loading /> : null}
    </>
  );
}
