/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { assemblyColor, assemblyComponent } from "../Graphs/Logic/formatUtils";

const Stat = ({ type, title, subtitle }) => {
  let color = "#000";
  let icons = [];
  let iconSize = "50px";

  switch (type) {
    case "currentTotalPower":
    case "totalCapacity": {
      color = assemblyColor.Genset;
      //subtitle = "Total Power (real time)";
      icons.push(assemblyComponent.solar);
      icons.push(assemblyComponent.genset);
      break;
    }
    case "currentSolarPower":
      color = assemblyColor.Genset;
      //subtitle = "Total Solar (real time)";
      icons.push(assemblyComponent.solar);
      iconSize = "80px";
      break;
    case "availableGenset":
      color = assemblyColor.Genset;
      //subtitle = "Unused Genset";
      icons.push(assemblyComponent.genset);
      iconSize = "80px";
      break;
    case "availableBess":
      color = assemblyColor.BESS;
      //subtitle = "Available BESS";
      icons.push(assemblyComponent.bess);
      iconSize = "80px";
      break;
  }

  return (
    <Box
      className="stat-box"
      mt="1rem"
      minHeight="200px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width="100%"
        height="50%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-evenly"
      >
        {icons.map((icon, index) => {
          const AssemblyComponent = icon;
          return (
            <AssemblyComponent
              className="large-assembly-icon"
              style={{
                "--assembly-icon-color": color,
              }}
              width={iconSize}
              height={iconSize}
              key={index}
            />
          );
        })}
      </Box>
      <Typography variant="h3" color={color}>
        {title}
      </Typography>
      <Typography variant="caption">{subtitle}</Typography>
    </Box>
  );
};

Stat.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default Stat;
