/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

/**
 * Check if an object has the desired keys
 *
 * @param {object}
 * @param {keys}
 */
export function hasKeys(object, keys) {
  const requiredKeys = new Set(keys);
  let isValid = true;
  let notExistentKeys = [];

  requiredKeys.forEach((key) => {
    if (!Object.hasOwn(object, key)) {
      isValid = false;
      notExistentKeys.push(key);
    }
  });
  return { is_valid: isValid, keys: notExistentKeys };
}

export const RowData = ({ alarm, index, flags }) => {
  const theme = useTheme();
  const [newDateTime, setNewDateTime] = useState([]);
  useEffect(() => {
    setNewDateTime(alarm.date.split(" "));
  }, [alarm]);

  const getAlarmColor = (alarm) => {
    if (alarm.alarm_type?.toLowerCase() === "amber") {
      return theme.palette.warning.main;
    }
    if (alarm.alarm_type?.toLowerCase() === "red") {
      return theme.palette.error.main;
    }
    return null;
  };

  return (
    <TableRow key={index}>
      <TableCell className="background" style={{ paddingLeft: "2em" }}>
        <Box sx={{ ...theme.classes.point, backgroundColor: getAlarmColor(alarm) }}></Box>
      </TableCell>
      <TableCell className="background">
        {flags.alarmDocLink ? (
          <a
            href={
              "https://www.notion.so/58362fce0df34b8f82a2c8ae407b8a57?v=87e8048b4ec8456c9730cff4ef0d1fde&q=" +
              alarm.name
            }
            target="_blank"
            rel="noreferrer"
            style={{ color: "rgba(0, 0, 0, 0.87)" }}
          >
            {alarm.name}
          </a>
        ) : (
          alarm.name
        )}
      </TableCell>
      <TableCell className="background">{alarm.type}</TableCell>
      <TableCell className="background">{newDateTime[1]} UTC</TableCell>
      <TableCell className="background">{newDateTime[0]}</TableCell>
      <TableCell className="background">
        <span className="material-symbols-outlined"></span>
      </TableCell>
    </TableRow>
  );
};

const NoAlarms = () => {
  return (
    <TableRow>
      <TableCell sx={{ color: "#686868", textAlign: "center" }} colSpan={6}>
        No current alarms
      </TableCell>
    </TableRow>
  );
};

const Collapsible = ({ alarms, open, flags }) => {
  let showAlarms = <NoAlarms flags={flags} />;
  let counter = 0;
  if (alarms.length > 0) {
    showAlarms = alarms.map((alarmRow, index) => {
      const keys = hasKeys(alarmRow, ["name", "type", "date", "alarm_type"]);
      if (keys.is_valid) {
        if (index >= 1) {
          if (alarms[index - 1].date.split(" ")[1] !== alarmRow.date.split(" ")[1]) {
            counter++;
            return (
              <React.Fragment key={"empty-" + counter}>
                <TableRow key={"empty-" + counter}>
                  <TableCell colSpan={6} />
                </TableRow>
                <RowData alarm={alarmRow} index={index} key={counter} flags={flags} />
              </React.Fragment>
            );
          }
        }
      }
      counter++;
      return <RowData alarm={alarmRow} index={index} key={counter} flags={flags} />;
    });
  }
  return (
    <TableCell className="table-collapse" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
      <div className="before-collapse">
        <Collapse className="after-collapse" in={open} timeout="auto" unmountOnExit>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table size="small" aria-label="collapsible-alarms" sx={{ backgroundColor: "white" }}>
              <TableBody>
                <TableRow>
                  <TableCell width={"4%"} />
                  <TableCell className="font">Issue</TableCell>
                  <TableCell className="font" width={"22%"}>
                    Equipment
                  </TableCell>
                  <TableCell className="font" width={"13%"}>
                    Time
                  </TableCell>
                  <TableCell className="font" width={"12%"}>
                    Date
                  </TableCell>
                  <TableCell width={"6%"}></TableCell>
                </TableRow>
                {showAlarms}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </div>
    </TableCell>
  );
};

// Define prop types for the component
Collapsible.propTypes = {
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      date: PropTypes.string.isRequired,
      alarm_type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  open: PropTypes.bool.isRequired,
  flags: PropTypes.object,
};

RowData.propTypes = {
  alarm: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    date: PropTypes.string.isRequired,
    alarm_type: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  flags: PropTypes.object,
};

export default withLDConsumer()(Collapsible);
