/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { Button, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import NewUserModal from "./NewUserModal";
import UserRoles from "../../lib/UserRoles";

const styles = {
  searchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "288px",
    height: "36px",
  },
  buttonContainer: {
    marginRight: "20px",
    width: "184px",
  },
  searchInput: {
    paddingLeft: "12px",
    fontSize: 16,
    height: 40,
  },
};

export default function UsersTable({ onRowClick, usersList, onCreated }) {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const rows = usersList.users
    ? usersList.users.map((user) => ({
        id: user.name,
        name: user.name,
        email: user.email_address,
        role: user.roles[0],
        userRole: UserRoles[user.roles[0]] || user.roles[0],
        authorized_sites: user.authorized_sites,
        customer: user.customer,
      }))
    : [];

  const getSortedRows = (rows, searchTerm) => {
    let filteredRows = rows.filter((row) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    filteredRows.sort((a, b) => {
      return a.name.localeCompare(b.name); // Default ascending order
    });

    return filteredRows;
  };

  const filteredRows = getSortedRows(rows, searchTerm);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (params) => {
    onRowClick(params.row);
  };

  const columns = [
    {
      field: "name",
      headerName: "User Name",
      flex: 1,
    },

    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
    },

    {
      field: "userRole",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      flex: 0,
      align: "right",
      sortable: false,
      renderCell: (params) => <ChevronRightIcon />,
    },
  ];

  const handleNew = () => {
    setIsNewUserModalOpen(true); // Open the user modal
  };

  const handleClose = () => {
    setIsNewUserModalOpen(false); // Close the user modal
    onCreated();
  };

  return (
    <>
      <div style={theme.classes.container}>
        <div style={styles.buttonContainer}>
          <Button variant="contained" sx={{ width: "100%" }} onClick={handleNew}>
            New User
          </Button>
        </div>
        <NewUserModal open={isNewUserModalOpen} onClose={() => handleClose()} />
        <div style={styles.searchContainer}>
          <TextField
            id="search"
            label=""
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search users"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: styles.searchInput,
            }}
            sx={{
              fontSize: 16,
            }}
          />
        </div>
      </div>
      {
        <div style={theme.classes.tableContainer}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            autoHeight
            autoWidth
            disableSelectionOnClick
            sortingOrder={["asc", "desc"]}
            onRowClick={handleRowClick}
            hideFooter
            sx={{
              "&, .MuiDataGrid-columnHeader": {
                borderTop: "none",
              },
              "&, [class^=MuiDataGrid]": {
                overflowX: "hidden",
                borderLeft: "none",
                borderRight: "none",
              },
            }}
          />
        </div>
      }
    </>
  );
}

UsersTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  onCreated: PropTypes.func,
  usersList: PropTypes.object,
};
