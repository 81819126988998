/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
  Grid,
} from "@mui/material";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getUserProfileQuery, getUserProfileMutation, getSiteListQuery } from "../../lib/Queries";
import Loading from "../Shared/Loading";
import DataComponentError from "../Shared/DataComponentError";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

const AccountSettings = ({ flags }) => {
  const theme = useTheme();
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [selectedSites, setSelectedSites] = useState(new Set());
  const [allSelected, setAllSelected] = useState(false);

  const addSiteUuid = (uuid) => {
    setSelectedSites((prevItems) => new Set(prevItems).add(uuid));
  };

  const removeSiteUuid = (removeUuid) => {
    setSelectedSites((prevItems) => {
      const newSet = new Set(prevItems);
      newSet.delete(removeUuid);
      return newSet;
    });
  };

  const handleSiteSelectionChange = (uuid, event) => {
    const selected = event.target.checked;
    if (selected) {
      addSiteUuid(uuid);
    } else {
      removeSiteUuid(uuid);
    }
  };

  const {
    isLoading: profileIsLoading,
    error: profileError,
    data: profileData,
    refetch: refetchProfile,
  } = useQuery({
    ...getUserProfileQuery(),
  });

  const {
    data: siteList,
    isLoading: siteListIsLoading,
    refetch: refetchSiteList,
  } = useQuery({
    ...getSiteListQuery(),
  });

  const mutation = useMutation({
    ...getUserProfileMutation(),
    onSuccess: () => {
      refetchProfile();
      refetchSiteList();
    },
  });

  const isLoading = siteListIsLoading || profileIsLoading;

  const updateAllSelected = (selectedSites, siteList) => {
    if (!siteList || !siteList.sites) return;
    if (selectedSites.size === siteList.sites.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  const handleReceiveNotificationChange = (ev) => {
    setNotificationsEnabled(ev.target.checked);
  };

  const getAllSiteUuids = () => {
    return siteList.sites.map((site) => site.uuid);
  };

  const handleToggleSelectAll = () => {
    if (allSelected) {
      setSelectedSites(new Set());
    } else {
      setSelectedSites(new Set(getAllSiteUuids()));
    }
    setAllSelected(!allSelected);
  };

  const selectedSitesChanged = () => {
    if (isLoading) return false;
    const currentSiteUuids = new Set(profileData.sites_preference);
    return !_.isEqual(currentSiteUuids, selectedSites);
  };

  const globalNotificationsChanged = () => {
    if (profileIsLoading) return false;
    return profileData.global_notifications !== notificationsEnabled;
  };

  const saveProfile = () => {
    let profile = {
      global_notifications: notificationsEnabled,
      sites_preference: Array.from(selectedSites),
    };
    mutation.mutate(profile);
  };

  const siteIsSelected = (site) => {
    return selectedSites.has(site.uuid);
  };

  useEffect(() => {
    if (profileData) {
      setSelectedSites(new Set(profileData.sites_preference));
      setNotificationsEnabled(profileData.global_notifications);
    }
  }, [profileData]);

  useEffect(() => {
    updateAllSelected(selectedSites, siteList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSites, siteList]);

  const styles = {
    genericPanel: {
      width: "100%",
      backgroundColor: "#fff",
      border: "1px solid #E1E2E4",
      borderRadius: "5px",
      padding: "2em",
      minHeight: "150px",
    },
    caption: {
      color: theme.palette.textIcon.main,
      marginBottom: "1rem",
    },
    subtitle: {
      color: "#636363",
    },
    settingsPanel: {
      width: "100%",
      py: "2em",
      px: "3em",
    },
    saveContainer: {
      pt: "2em",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-end",
      marginBottom: "2em",
    },
    pageContainer: {
      display: "flex",
      justifyContent: "center",
      marginLeft: "80px",
      width: "calc(100% - 80px)",
    },
    page: {
      flexDirection: "column",
      paddingTop: "3em",
      paddingLeft: "4em",
      paddingRight: "4em",
      width: "100%",
      maxWidth: "1400px",
    },
    siteGroup: {
      color: "#636363",
      marginBottom: "1.3em",
      marginTop: "1.5em",
    },
    controlList: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridAutoFlow: "column",
    },
    noSitesContainer: {
      display: "flex",
      justifyContent: "center",
    },
  };

  const getSiteControl = (siteName, siteUuid, selected) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={selected}
            onChange={(ev) => {
              handleSiteSelectionChange(siteUuid, ev);
            }}
            size="large"
            disabled={!notificationsEnabled}
          />
        }
        label={<Typography variant="body2">{siteName}</Typography>}
      />
    );
  };

  const getSiteControlList = (siteList, sitesState) => {
    const filteredSites = siteList.sites.filter((site) => sitesState.includes(site.state));

    const orderedSites = filteredSites.sort((a, b) => a.site_name.localeCompare(b.site_name));

    if (!orderedSites.length) {
      return (
        <Box sx={styles.noSitesContainer}>
          <Typography variant="body-2">No sites</Typography>
        </Box>
      );
    }

    const rowCount = Math.ceil(orderedSites.length / 3);
    return (
      <Box
        sx={[styles.controlList, { gridTemplateRows: `repeat(${rowCount}, minmax(4rem, auto))` }]}
      >
        {orderedSites.map((site) => {
          return getSiteControl(site.site_name, site.uuid, siteIsSelected(site));
        })}
      </Box>
    );
  };

  const getNotificationsPanel = () => (
    <Box sx={[styles.genericPanel, { marginTop: "2rem" }]}>
      <Typography variant="subtitle2" sx={styles.subtitle}>
        Notifications
      </Typography>
      {profileData && (
        <Box sx={styles.settingsPanel}>
          <FormGroup>
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsEnabled}
                    onChange={handleReceiveNotificationChange}
                    size="large"
                  />
                }
                label={<Typography variant="body2">Receive Notifications</Typography>}
              />
              <Typography variant="caption" sx={styles.caption}>
                Enables notifications to your email address, {profileData.email_address}.
              </Typography>
              <Typography variant="caption" sx={styles.caption}>
                Turning this off disables all of your ScaleOS notifications, but preserves your
                preferences if you opt back into notifications.
              </Typography>
            </Box>
          </FormGroup>
        </Box>
      )}
      {isLoading ? <Loading /> : null}
      {profileError ? <DataComponentError /> : null}
    </Box>
  );

  const getSiteAlarmsPanel = () => (
    <Box sx={[styles.genericPanel, { marginTop: "2rem" }]}>
      <Typography variant="subtitle2" sx={styles.subtitle}>
        Site Alarms
      </Typography>
      {!isLoading && (
        <>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="caption" sx={styles.caption}>
                Regardless of these settings, you’ll still receive the daily email summary of both
                alarms (red) and warnings (amber).
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleToggleSelectAll}
                variant="outlined"
                disabled={!notificationsEnabled}
              >
                Select / Deselect All
              </Button>
            </Grid>
          </Grid>
          <Box sx={styles.settingsPanel}>
            <Typography variant="h5" sx={styles.siteGroup}>
              Operating
            </Typography>
            {getSiteControlList(siteList, ["Operating", "commissioned"])}
            <Typography variant="h5" sx={[styles.siteGroup]}>
              Commissioning
            </Typography>
            {getSiteControlList(siteList, ["Commissioning"])}
            <Typography variant="h5" sx={[styles.siteGroup]}>
              Pre-Commissioning
            </Typography>
            {getSiteControlList(siteList, ["Pre-Commissioning"])}
          </Box>
        </>
      )}
      {isLoading ? <Loading /> : null}
      {profileError ? <DataComponentError /> : null}
    </Box>
  );

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.page}>
        <Box>
          <Typography
            className="dashboard"
            variant="h4"
            component="div"
            sx={{ color: "#325182", marginBottom: "1rem" }}
          >
            My Settings
          </Typography>
          <Typography variant="caption" sx={styles.caption}>
            Edit selections, then select “Save Changes” at the bottom of the page.
          </Typography>
          {getNotificationsPanel()}
          {getSiteAlarmsPanel()}
          <Box sx={styles.saveContainer}>
            <Button
              onClick={saveProfile}
              variant="contained"
              sx={{ marginLeft: "1em", width: "150px" }}
              disabled={!globalNotificationsChanged() && !selectedSitesChanged()}
            >
              {mutation.isSuccess && !globalNotificationsChanged() && !selectedSitesChanged()
                ? "Saved"
                : "Save Changes"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AccountSettings.propTypes = {
  flags: PropTypes.object,
};

export default withLDConsumer()(AccountSettings);
