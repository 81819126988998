/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import useUserStore from "./../../state/UserStore";
import { useAuth0 } from "@auth0/auth0-react";

const Topbar = ({ flags }) => {
  const { logout: authLogout } = useAuth0();
  /*
  NTM - We used to pass the user into the top bar component.  We have a store now, 
  so we don't need to do that. Just get it from the store.  If something is 
  global (like the logged in user) and in a store, we don't usually need to pass it as
  a prop to components.
   */
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userStore = useUserStore((state) => state);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = (event) => {
    if (window.analytics) {
      window.analytics.track("Log Out", { app: "NOC" });
    }
    userStore.logout();
    localStorage.clear();

    authLogout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ minHeight: "0px !important" }}>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}></Box>
          <Box
            flexGrow={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {flags.stagingBanner ? (
              <>
                <Typography variant="body2" color="error">
                  WARNING: This is a non-production environment!
                </Typography>
                <Typography variant="caption">
                  <a href="https://operate.scalemicrogrids.com">
                    If you are looking for production, click here
                  </a>
                </Typography>
              </>
            ) : null}
          </Box>
          <Box sx={{ flexGrow: 0, top: "10px !important" }}>
            <Box onClick={handleOpenUserMenu}>
              <IconButton sx={{ paddingRight: "0px" }}>
                <Avatar alt={userStore.user?.name} src={userStore.user?.picture} />
              </IconButton>
              <KeyboardArrowDownIcon
                sx={{
                  zIindex: 9999,
                  color: "#6C6C6C",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Menu
              sx={{
                mt: "45px",
                right: "30px",
                ".MuiMenu-paper": {
                  border: "1px solid #e1e2e4",
                  borderRadius: "5px",
                  width: "130px",
                },
              }}
              className="profile-menu-base"
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key="profile"
                onClick={(e) => (window.location.href = "/account")}
                selected={false}
              >
                <Typography className="li-text" textAlign="center">
                  My Settings
                </Typography>
              </MenuItem>
              <MenuItem key="logout" onClick={(e) => handleLogout(e)} selected={false}>
                <Typography className="li-text" textAlign="center">
                  Log out
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Topbar.propTypes = {
  flags: PropTypes.object,
};

export default withLDConsumer()(Topbar);
