/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
const UserRoles = {
  "operate:admin": "Scale Admin",
  "operate:user": "Scale Viewer",
  "portal:user": "Customer Viewer",
};

export default UserRoles;
