/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const SiteLink = ({ site, variant, color }) => {
  const handleClick = (e) => {
    localStorage.setItem("site", `${site.uuid}`);
  };

  return (
    <Link
      to={site.uuid ? `/site/${site.name.replace(/ /g, "-")}` : null}
      onClick={site.uuid ? handleClick : null}
    >
      <Typography color={color || "secondary"} variant={variant || "h5"}>
        {site.name}
      </Typography>
    </Link>
  );
};

SiteLink.propTypes = {
  site: PropTypes.object.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default SiteLink;
