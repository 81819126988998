/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import { ReactComponent as BESS } from "../../../static/images/bess.svg";
import { ReactComponent as Genset } from "../../../static/images/generator.svg";
import { ReactComponent as Solar } from "../../../static/images/solar.svg";

export const fatherAssemblies = ["Solar", "Genset"];
export const keys = ["Load", "Utility", "Genset", "Solar", "BESS"];
export const nodesOrder = ["Utility", "Load", "Solar", "BESS", "Genset"];
export const loadChilds = ["Solar", "BESS", "Genset"];

export const assemblyColor = {
  Utility: "#c6c6c6",
  Solar: "#a6e56a",
  Genset: "#84b953",
  BESS: "#57bcdc",
  Load: "#444444",
};
export const assemblyFillColor = {
  Utility: "#EBEBEB",
  Solar: "#ECFFDB",
  Genset: "#EFF5E9",
  BESS: "#E6F7FD",
};
export const assemblyComponent = {
  bess: BESS,
  genset: Genset,
  solar: Solar,
};

interface TSMetric {
  uuid: string;
  name: string;
  unit: string;
  table_name: string;
}

export const formatDateString = (dateString: string, includeTime: boolean = true): string => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const formattedHour = date.getHours() % 12 || 12;
  const period = date.getHours() < 12 ? "am" : "pm";
  const formattedMinute = date.getMinutes().toString().padStart(2, "0");
  const formatHour = `${formattedHour}:${formattedMinute}${period}`;

  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;

  return includeTime ? `${formattedDate} ${formatHour}` : formattedDate;
};

export const getOrdinalSuffix = (day: number): string => {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = day % 10;

  if (day >= 11 && day <= 13) {
    return "th";
  }

  return suffixes[remainder] || "th";
};

/**
 * Finds the time_series name associated with a given UUID. It searches
 * within the 'assembly' object's time_series array to find a matching time series.
 *
 * @param {string} uuid - The UUID of the time series to find.
 * @param {Object} site - The site object containing assemblies with time_series data.
 * @return {Object|null} The time_series object if found, or null if no matching time series is found.
 */
export const findSeries = (
  uuid: string,
  site: any,
): { tsMetric: TSMetric; assemblyName: string } | null => {
  const assembly = findAssembly(uuid, site);
  if (!assembly || !assembly.time_series) {
    return null;
  }

  const matchingTSMetric = assembly.time_series.find((ts: TSMetric) => ts.uuid === uuid);
  return matchingTSMetric !== undefined
    ? { tsMetric: matchingTSMetric, assemblyName: assembly.name }
    : null;
};

/**
 * finds the assembly that a particular time series belongs to, by the series UUID
 * @param {string} uuid - The UUID of the time series to find.
 * @param {Object} site - The site object containing assemblies with time_series data.
 * @return {Object|null} The assembly object if found, or null if no matching time series is found.
 */
export const findAssembly = (uuid: string, site: any): any | null => {
  if (!site || !site.assemblies) {
    return null;
  }

  const tsAssembly = site.assemblies.find((assembly: any) =>
    assembly?.time_series?.some((ts: TSMetric) => ts.uuid === uuid),
  );

  return tsAssembly !== undefined ? tsAssembly : null;
};
