import React from "react";
import PropTypes from "prop-types";

const CustomizedTick = ({ x, y, payload, unit, fontSize = 8, colorZero = true }) => {
  const getZeroColor = () => {
    if (!colorZero) return "#000";

    return payload.value === 0 ? "#325182" : "#000";
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={2} fill={getZeroColor()} fontSize={fontSize} textAnchor="end">
        {payload.value}
        {unit}
      </text>
    </g>
  );
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  unit: PropTypes.string,
  fontSize: PropTypes.number,
  colorZero: PropTypes.bool,
};

export default CustomizedTick;
