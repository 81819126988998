import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ReactComponent as Logo } from "./../../static/images/scale_microgrids_logo.svg";

export default function NotAuthorized() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={{ pt: "4rem", display: "flex", justifyContent: "center", width: "100%" }}>
        <Logo style={{ width: "20rem" }} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "25rem",
            pb: "10rem",
            pt: "2rem",
          }}
        >
          <Typography variant="h4" sx={{ mb: "1rem" }}>
            See an admin for access
          </Typography>
          <Typography variant="body1" color="#888" sx={{ textAlign: "center" }}>
            You can sign in with your Google account, but first you’ll need to request access from a
            ScaleOS Operate admin.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
