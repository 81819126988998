/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AlarmDetail from "./AlarmDetail";
import AlarmSummary from "./AlarmSummary";
import { useTheme } from "@mui/material/styles";

const AlarmTooltipContents = ({ alarms, timezone }) => {
  const theme = useTheme();

  const styles = {
    alarmDetailContainer: {
      ...theme.classes.customTooltipContainer,
      ...theme.classes.alarmDetailContainer,
    },
  };

  if (!(alarms?.length > 0)) return null;

  return (
    <Box mt="0.5rem">
      <Box sx={styles.alarmDetailContainer}>
        {alarms.length <= 4 ? (
          alarms.map((alarm, index) => {
            return (
              <AlarmDetail
                key={"tt-alarm-" + index}
                alarmSeverity={alarm.alarm_type}
                alarmDate={alarm.timestamp}
                alarmName={alarm.description}
                alarmSource={alarm.source}
                resolved={Boolean(alarm.stop_time)}
              />
            );
          })
        ) : (
          <AlarmSummary alarms={alarms} />
        )}
      </Box>
    </Box>
  );
};

AlarmTooltipContents.propTypes = {
  alarms: PropTypes.array,
  timezone: PropTypes.string,
};

export default AlarmTooltipContents;
