import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const DeleteSiteModal = ({ open, onClose, onConfirm, siteName }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md" // Use maxWidth to control the width
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiPaper-root": {
          width: "48vw",
          margin: "0 64px",
          padding: "48px 64px",
          boxSizing: "border-box",
        },
      }}
    >
      <DialogTitle
        variant="h5"
        sx={{
          mb: 4,
          fontSize: "34px",
          fontWeight: 600,
        }}
      >
        Delete Site
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
          <p>Are you sure you want to delete the site “{siteName}”?</p>
          <p>This will delete all site data.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mt: 4 }}>
        <Button disabled onClick={onConfirm} variant="outlined" color="error">
          Delete Site
        </Button>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Don’t Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteSiteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  siteName: PropTypes.string.isRequired,
};

export default DeleteSiteModal;
