import React, { useState } from "react";
import { Badge, Divider, Box } from "@mui/material";
import AlarmDetail from "./AlarmDetail";
import PropTypes from "prop-types";
import { sortAlarms, getRedAlarms, getAmberAlarms } from "../../../lib/AlarmUtil";
import AlarmsModal from "./AlarmsModal";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";

const AlarmBadge = ({
  alarms,
  alarmsCount,
  children,
  timezone,
  badgeProps,
  source,
  hasAssets = true,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  if (alarms?.length === 0) return <></>;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getBadgeCount = (alarms) => {
    if (!alarms) return 0;
    return alarms.length;
  };

  const getBadgeColor = (alarmsCount) => {
    if (!alarmsCount) return "grey";
    let severity =
      alarmsCount.find((alarm) => alarm.severity === "Red")?.count > 0
        ? "error"
        : alarmsCount.find((alarm) => alarm.severity === "Amber")?.count === 0
          ? "grey"
          : "warning";
    return severity;
  };

  const showAlarmModal = (event) => {
    setModalOpen(true);
  };

  const styles = {
    popoverBox: {
      ...theme.classes.customTooltipContainer,
      ...theme.classes.alarmDetailContainer,
    },
    badge: {
      cursor: "pointer",
      "& .MuiBadge-badge": {
        "&:hover": {
          filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.35))",
        },
      },
    },
  };

  const displayAlarms = (alarms) => {
    if (!alarms) return <></>;
    const sortedAlarms = sortAlarms(alarms);
    return sortedAlarms.map((alarm) => (
      <Box key={alarm.name}>
        <AlarmDetail
          alarmSeverity={alarm.alarm_type}
          alarmDate={alarm.date}
          alarmName={alarm.name}
          alarmSource={alarm.source}
          timezone={timezone}
          resolved={false}
        />
      </Box>
    ));
  };

  const standardizeAlarms = (alarms) => {
    return alarms.map((alarm) => ({
      ...alarm,
      description: alarm.name,
      timestamp: alarm.date,
    }));
  };

  const getModalAlarms = () => {
    if (!alarms) return [];
    const redAlarms = sortAlarms(getRedAlarms(alarms));
    const amberAlarms = sortAlarms(getAmberAlarms(alarms));
    const allAlarms = [...redAlarms, ...amberAlarms];
    return standardizeAlarms(allAlarms);
  };

  const getBadgeContent = (alarms) => {
    const count = getBadgeCount(alarms);
    return (
      <>
        <span>{count}</span>
      </>
    );
  };

  return (
    <>
      <Badge
        color={getBadgeColor(alarmsCount)}
        badgeContent={getBadgeContent(alarms)}
        sx={styles.badge}
        {...badgeProps}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={showAlarmModal}
      />
      <AlarmsModal
        timezone={timezone}
        open={modalOpen}
        title="Active Alarms"
        alarms={getModalAlarms()}
        onClose={() => setModalOpen(false)}
        subtitle={source}
        hasAssets={hasAssets}
      />

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        transformOrigin={{ vertical: -5, horizontal: -5 }}
      >
        <Box sx={styles.popoverBox}>
          {displayAlarms(getRedAlarms(alarms))}
          {getRedAlarms(alarms).length > 0 && getAmberAlarms(alarms).length > 0 && (
            <Box sx={{ mt: ".8rem", mb: "1rem" }}>
              <Divider sx={{ mx: "1rem", borderBottomWidth: 2 }} />
            </Box>
          )}
          {displayAlarms(getAmberAlarms(alarms))}
        </Box>
      </Popover>
    </>
  );
};

AlarmBadge.propTypes = {
  alarms: PropTypes.array,
  alarmsCount: PropTypes.array,
  timezone: PropTypes.string,
  children: PropTypes.object,
  badgeProps: PropTypes.object,
  source: PropTypes.string,
  hasAssets: PropTypes.bool,
};

export default AlarmBadge;
