/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Box, Alert, Typography } from "@mui/material";

const DataComponentError = () => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "200px" }}
    >
      <Alert className={`banner banner-${"warning"}`} severity="warning">
        Error loading data
      </Alert>
      <Typography variant="caption">
        Something went wrong and we were unable to load the requested data
      </Typography>
    </Box>
  );
};

export default DataComponentError;
