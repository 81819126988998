import React from "react";
import PortfolioMap from "./PortfolioMap";
import PortfolioStats from "./PortfolioStats";
import { Box } from "@mui/material";

const PortfolioSummary = () => {
  return (
    <Box flexDirection="column" pt="10px" pl="4em" pr="4em" alignItems="center">
      <PortfolioMap />
      <PortfolioStats />
    </Box>
  );
};

export default PortfolioSummary;
