/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormGroup,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useMutation } from "@tanstack/react-query";
import {
  putOrganizationSettingsMutation,
  postOrganizationSettingsMutation,
  deleteOrganizationMutation,
} from "../../lib/Queries";
import states from "../../lib/States";
import countries from "../../lib/Countries";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import DeleteConfirmationModal from "./OrganizationDeleteModal";

const styles = {
  caption: {
    color: "rgba(0, 0, 0, 0.60)",
    marginBottom: "1rem",
  },
  settingsPanel: {
    width: "100%",
    py: "3rem",
    px: "4rem",
  },
  settingsNewPanel: {
    width: "100%",
    py: "3rem",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "700",
  },
  genericTab: {
    backgroundColor: "#fff",
    border: "1px solid #E1E2E4",
    borderRadius: "5px",
    padding: "2rem",
    width: "184px",
    height: "164px",
    justifyContent: "center",
    fontSize: 12,
  },
  genericTabLabel: {
    fontSize: 14,
  },
};

const OrganizationSettings = ({ organization, onClose, onOrganizationCreated }) => {
  const theme = useTheme();
  const [name, setName] = useState(organization?.name || null);
  const [dirty, setDirty] = useState(false);
  const [address1, setAddress1] = useState(organization?.address?.address1 || null);
  const [city, setCity] = useState(organization?.address?.city || null);
  const [zip, setZip] = useState(organization?.address?.zip || null);
  const [usState, setUsState] = useState(organization?.address?.state || null);
  const [country, setCountry] = useState(organization?.address?.country || "United States");
  const [isDifferentAddress, setIsDifferentAddress] = useState(false);
  const [open, setOpen] = useState(false); // State to control modal visibility
  const mutation = useMutation({
    ...putOrganizationSettingsMutation(),
  });

  const mutation_new_org = useMutation({
    ...postOrganizationSettingsMutation(),
  });

  const mutation_delete = useMutation({
    ...deleteOrganizationMutation(),
  });
  const saveSettings = (onSuccessCallback) => {
    const newSettings = {
      uuid: organization?.uuid,
      name: name,
      address: {
        address1: address1,
        city: city,
        state: usState,
        zip: zip,
        country: country,
      },
    };

    if (organization?.uuid) {
      mutation.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
          onClose();
          if (onSuccessCallback) onSuccessCallback();
        },
      });
    } else {
      // Use mutation_new_org if organization.uuid does not exist
      mutation_new_org.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
          onClose();
          if (onSuccessCallback) onSuccessCallback();
        },
      });
    }
  };

  const getStates = () => {
    const stateItems = Object.keys(states).map((stateAbbreviation) => (
      <MenuItem key={stateAbbreviation} value={stateAbbreviation}>
        {stateAbbreviation}
      </MenuItem>
    ));
    return stateItems;
  };

  const getCountries = () => {
    return countries.map((country) => (
      <MenuItem key={country} value={country}>
        {country}
      </MenuItem>
    ));
  };
  const allFieldsFilled = () => {
    return name && (!isDifferentAddress || (address1 && city && zip && usState && country));
  };

  const fieldsChanged = () => {
    // Compare each field with its original value
    if (name !== organization?.name) return true;
    if (address1 !== organization?.address?.address1) return true;
    if (city !== organization?.address?.city) return true;
    if (zip !== organization?.address?.zip) return true;
    if (usState !== organization?.address?.state) return true;
    if (country !== organization?.address?.country) return true;

    return false;
  };

  const handleDelete = () => {
    setOpen(true); // Open the confirmation modal
  };

  const confirmDelete = () => {
    mutation_delete.mutate(organization?.uuid, {
      onSuccess: () => {
        console.log("Organization deleted successfully");
        setOpen(false); // Close the modal on success
      },
      onError: (error) => {
        console.error("Error deleting organization:", error);
        setOpen(false); // Close the modal on error
      },
    });
  };

  const handleSaveAndClose = () => {
    saveSettings(() => {
      if (!organization?.uuid) {
        onOrganizationCreated(); // Trigger the refetch of the organization list
      }
    });
  };

  return (
    <Box>
      <Box sx={organization?.uuid ? theme.classes.genericPanel : {}}>
        {organization?.uuid ? (
          <Typography variant="subtitle2" sx={styles.subtitle}>
            Profile
          </Typography>
        ) : (
          <Typography
            sx={{
              mb: "1.5rem",
              mt: "-1.5rem",
              fontSize: "34px",
              fontWeight: 850,
              color: "rgba(0, 0, 0, 0.87)",
            }}
            variant="h5"
          >
            New Organization
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="column"
          sx={organization?.uuid ? styles.settingsPanel : { ...styles.settingsNewPanel }}
        >
          <FormGroup fullWidth variant="outlined" margin="normal">
            <Box mb="24px">
              <TextField
                id="name"
                name="name"
                label="Organization Name"
                value={name}
                size="small"
                required
                variant="outlined"
                onChange={(ev) => {
                  setName(ev.target.value);
                  setDirty(true);
                }}
                sx={{ width: "392px", fontSize: 16, fontWeight: 400 }}
                InputProps={{
                  sx: { fontSize: 16, fontWeight: 400 },
                }}
              />
            </Box>
            <Box mb="24px">
              <FormControlLabel
                control={
                  <Checkbox
                    size="large"
                    checked={isDifferentAddress}
                    onChange={(ev) => setIsDifferentAddress(ev.target.checked)}
                    defaultChecked
                    sx={{
                      "&.Mui-checked": {
                        color: "#007A99",
                      },
                    }}
                  />
                }
                label="Corporate address is different from site address"
              />
            </Box>
            {isDifferentAddress && (
              <Box>
                <Box display="flex" flexDirection="row" mb="1.5rem" alignItems="center">
                  <TextField
                    id="address"
                    name="address"
                    label="Corporate Address"
                    size="small"
                    required
                    value={address1}
                    variant="outlined"
                    onChange={(ev) => {
                      setAddress1(ev.target.value);
                      setDirty(true);
                    }}
                    sx={{
                      width: "24.5rem",
                      fontSize: 16,
                      fontWeight: 400,
                      marginRight: "1.5rem",
                    }}
                    InputProps={{
                      sx: { fontSize: 16, fontWeight: 400 },
                    }}
                  />
                  <TextField
                    id="city"
                    name="city"
                    size="small"
                    label="City"
                    required
                    value={city}
                    variant="outlined"
                    onChange={(ev) => {
                      setCity(ev.target.value);
                      setDirty(true);
                    }}
                    sx={{
                      width: "11.5rem",
                      fontSize: 16,
                      fontWeight: 400,
                      marginRight: "1.5rem",
                    }}
                    InputProps={{
                      sx: { fontSize: 16, fontWeight: 400 },
                    }}
                  />
                  <FormControl sx={{ width: "5rem", marginRight: "1.5rem" }}>
                    <InputLabel htmlFor="usState">State</InputLabel>
                    <Select
                      id="usState"
                      name="usState"
                      size="small"
                      label="State"
                      required
                      value={usState}
                      variant="outlined"
                      onChange={(ev) => {
                        setUsState(ev.target.value);
                        setDirty(true);
                      }}
                      sx={{
                        "& .MuiSelect-select": {
                          fontSize: 16,
                          fontWeight: 400,
                        },
                      }}
                    >
                      {getStates()}
                    </Select>
                  </FormControl>
                  <TextField
                    id="zip"
                    name="zip"
                    label="ZIP Code"
                    size="small"
                    required
                    value={zip}
                    variant="outlined"
                    onChange={(ev) => {
                      setZip(ev.target.value);
                      setDirty(true);
                    }}
                    sx={{
                      width: "128px",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                    InputProps={{
                      sx: { fontSize: 16, fontWeight: 400 },
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="row" mb="1.5rem" alignItems="center">
                  <FormControl sx={{ width: "10rem", mt: "1rem" }}>
                    <InputLabel htmlFor="country">Country</InputLabel>
                    <Select
                      id="country"
                      name="country"
                      label="Country"
                      size="small"
                      value={country}
                      disabled
                      variant="outlined"
                      onChange={(ev) => {
                        setCountry(ev.target.value);
                        setDirty(true);
                      }}
                      sx={{
                        "& .MuiSelect-select": {
                          fontSize: 16,
                          fontWeight: 400,
                        },
                      }}
                    >
                      {getCountries()}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
          </FormGroup>
        </Box>
      </Box>
      <Box
        mt={organization?.uuid ? "1.5rem" : "0"}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        {organization?.uuid ? (
          <Button
            variant="outlined"
            disabled={!organization?.uuid}
            onClick={handleDelete}
            color="error"
          >
            Delete Organization...
          </Button>
        ) : (
          <Box flexGrow={1} />
        )}
        {!organization?.uuid && (
          <Button
            variant="outlined"
            sx={{
              marginRight: "1em",
              width: "81px",
              color: "rgba(0, 0, 0, 0.87)",
              borderColor: "rgba(0, 0, 0, 0.87)",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        )}
        <LoadingButton
          loading={mutation?.isLoading}
          onClick={dirty ? () => handleSaveAndClose() : () => {}}
          variant="contained"
          sx={{ width: "178px", marginRight: organization?.uuid ? "0em" : "-3em" }}
          disabled={!dirty || (!allFieldsFilled() && !mutation?.isSuccess) || !fieldsChanged()}
          color={mutation?.isSuccess && !dirty ? "success" : "primary"}
        >
          {mutation.isSuccess && !dirty
            ? "Saved"
            : organization?.uuid
              ? "Save Changes"
              : "Create Organization"}
        </LoadingButton>
      </Box>
      <DeleteConfirmationModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={confirmDelete}
        organizationName={organization?.name}
      />
    </Box>
  );
};

OrganizationSettings.propTypes = {
  organization: PropTypes.object,
  onClose: PropTypes.func,
  onOrganizationCreated: PropTypes.func,
};

export default OrganizationSettings;
