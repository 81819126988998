/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";

import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import AccountBoxIcon from "@mui/icons-material/Portrait";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../static/images/logo.svg";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import useUserStore from "./../../state/UserStore";

const drawerWidth = 80;

const tabStyle = {
  "&.Mui-selected": {
    color: "#FFF",
    width: "48px",
    height: "48px",
    borderRadius: "5px",
    minWidth: "48px",
    backgroundColor: "#395d96",
  },
};

const Sidebar = ({ flags }) => {
  const userStore = useUserStore((state) => state);
  const loc = useLocation();
  let pathname = loc.pathname;
  if (!["/dashboard", "/account", "/portfolio", "/settings"].includes(pathname))
    pathname = "/dashboard";

  const [activeTab, setActiveTab] = useState(pathname);

  const handleChange = (ev, val) => {
    setActiveTab(val);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          background: "#325182 !important",
          overflow: "hidden",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <a href="https://www.scalemicrogrids.com" target="_blank" rel="noreferrer">
          <Logo style={{ width: "48px", height: "8px" }} />
        </a>
      </Toolbar>
      <Tabs
        orientation="vertical"
        value={activeTab}
        onChange={handleChange}
        aria-label="Left nav bar"
        sx={{
          "& .MuiTabs-flexContainerVertical": {
            alignItems: "center",
          },
          ".MuiTab-root": {
            color: "#7f99c2",
          },
        }}
        TabIndicatorProps={{
          style: { background: "transparent", height: "10px", top: "35px" },
        }}
        scrollButtons={false}
        centered={true}
      >
        <Tab
          disableRipple={true}
          icon={<DashboardRoundedIcon sx={{ width: "20px", height: "20px" }} fontSize="inherit" />}
          value="/dashboard"
          to="/dashboard"
          component={Link}
          sx={tabStyle}
        />
        <Tab
          disableRipple={true}
          icon={<AccountBoxIcon sx={{ width: "20px", height: "20px" }} fontSize="inherit" />}
          value="/account"
          to="/account"
          component={Link}
          sx={tabStyle}
        />
        {flags.portfolioPage && (
          <Tab
            disableRipple={true}
            icon={<BoltRoundedIcon sx={{ width: "20px", height: "20px" }} fontSize="inherit" />}
            value="/portfolio"
            to="/portfolio"
            component={Link}
            sx={tabStyle}
          />
        )}
        {flags.siteFinancialsDemo && (
          <Tab
            disableRipple={true}
            icon={<BoltRoundedIcon sx={{ width: "20px", height: "20px" }} fontSize="inherit" />}
            value="/markets"
            to="/markets"
            component={Link}
            sx={tabStyle}
          />
        )}
        {(userStore.isAdmin() && flags.showSettings) && (
          <Tab
            disableRipple={true}
            icon={
              <SettingsOutlinedIcon sx={{ width: "20px", height: "20px" }} fontSize="inherit" />
            }
            value="/settings"
            to="/settings"
            component={Link}
            sx={tabStyle}
          />
        )}
      </Tabs>
    </Drawer>
  );
};

Sidebar.propTypes = {
  flags: PropTypes.object,
};

export default withLDConsumer()(Sidebar);
