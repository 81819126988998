import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const DeleteConfirmationModal = ({ open, onClose, onConfirm, organizationName }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md" // Use maxWidth to control the width
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiPaper-root": {
          width: "48vw",
          margin: "0 64px",
          padding: "48px 64px",
          boxSizing: "border-box",
        },
      }}
    >
      <DialogTitle
        variant="h5"
        sx={{
          mb: 4,
          fontSize: "34px",
          fontWeight: 600,
        }}
      >
        Delete Organization and Related Data
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
          <p>
            Are you sure you want to delete the organization “{organizationName}”? This will delete
            all organization data, all related sites, and all related users.
          </p>
          <p>If you are unsure, please consult with a member of the Asset Management team.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mt: 4 }}>
        <Button disabled onClick={onConfirm} variant="outlined" color="error">
          Delete Organization, Sites, & Users
        </Button>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Don’t Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  organizationName: PropTypes.string.isRequired,
};

export default DeleteConfirmationModal;
