import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Chip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import PropTypes from "prop-types";

import DemoData from "./DemoData";

const EventTable = ({ entity, resources }) => {
  const [openRows, setOpenRows] = useState([]);
  const [eventList, setEventList] = useState(DemoData.events);

  const tableStyles = {
    left: {
      borderTop: "1px solid #E1E2E4",
      borderBottom: "1px solid #E1E2E4",
      borderLeft: "1px solid #E1E2E4",
      borderRadius: "5px 0 0 5px",
    },
    middle: {
      borderTop: "1px solid #E1E2E4",
      borderBottom: "1px solid #E1E2E4",
    },
    right: {
      borderTop: "1px solid #E1E2E4",
      borderBottom: "1px solid #E1E2E4",
      borderRight: "1px solid #E1E2E4",
      borderRadius: "0 5px 5px 0",
    },
  };

  useEffect(() => {
    if (resources && eventList[entity]) {
      let syntheticEvents = { ...eventList };
      const events = syntheticEvents[entity];
      events.forEach((ev, i) => {
        let participants = resources.BESS.array.map((b) => {
          let bessPercent = DemoData.getBessPercent();
          return {
            name: b.name,
            site: b.site_name,
            energy_capacity: b.energy_capacity,
            energy: Math.round(b.energy_capacity * bessPercent),
            revenue:
              Math.round(b.energy_capacity * DemoData.revenue[ev.program] * bessPercent) || 0,
          };
        });
        syntheticEvents[entity][i].participants = participants;
      });
      setEventList(syntheticEvents);
    }
  }, [resources]);

  const formatEventTimes = (start, stop) => {
    let dateString = start.toLocaleDateString();
    let delta = stop.getTime() - start.getTime();
    return dateString + ", Duration: " + delta / 1000 / 60 / 60 + " hours";
  };

  const calculateTotal = (participants, field) => {
    let sum = 0;
    participants.forEach((p) => {
      sum = sum + p[field] || 0;
    });
    return sum;
  };

  const calculateTotalEnergyRange = (participants) => {
    let min = 0;
    let max = 0;
    participants.forEach((p) => {
      let range = calculateEnergyRange(p);
      min = min + range.min;
      max = max + range.max;
    });
    return min + "kWh - " + max + "kWh";
  };

  const calculateTotalRevenueRange = (participants, program) => {
    let min = 0;
    let max = 0;
    participants.forEach((p) => {
      let range = calculateRevenueRange(p, program);
      min = min + range.min;
      max = max + range.max;
    });
    return "$" + min + " - $" + max;
  };

  const calculateEnergyRange = (participant) => {
    return {
      min: Math.round((participant.energy_capacity || 0) * DemoData.bessRange.min),
      max: Math.round((participant.energy_capacity || 0) * DemoData.bessRange.max),
    };
  };

  const calculateRevenueRange = (participant, program) => {
    return {
      min: Math.round(
        (participant.energy_capacity || 0) * DemoData.bessRange.min * DemoData.revenue[program],
      ),
      max: Math.round(
        (participant.energy_capacity || 0) * DemoData.bessRange.max * DemoData.revenue[program],
      ),
    };
  };

  if (!eventList[entity]) {
    return (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Typography color="secondary" variant="h5">
          No events for the selected utility or ISO
        </Typography>
      </Box>
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table" sx={{ backgroundColor: "#F8FAFB" }}>
        <TableHead>
          <TableRow>
            <TableCell>Event ID</TableCell>
            <TableCell align="left">Program</TableCell>
            <TableCell></TableCell>
            <TableCell align="left">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventList[entity].map((event) => (
            <React.Fragment key={event.id}>
              <TableRow>
                <TableCell sx={tableStyles.left}>
                  <Typography variant="body1" color="secondary">
                    {event.id}
                  </Typography>
                </TableCell>
                <TableCell align="left" sx={tableStyles.middle}>
                  <Typography variant="subtitle1" color="secondary">
                    {event.program}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    label={event.stop.getTime() < new Date().getTime() ? "Closed" : "Upcoming"}
                    color={event.stop.getTime() < new Date().getTime() ? "lightGrey" : "success"}
                  />
                </TableCell>
                <TableCell align="left" sx={tableStyles.middle}>
                  <Typography variant="body1" color="grey">
                    {formatEventTimes(event.start, event.stop)}
                  </Typography>
                </TableCell>
                {event.participants?.length > 0 ? (
                  <TableCell align="right" sx={tableStyles.right}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        let newOpenRows = [...openRows];
                        if (newOpenRows.includes(event.id)) {
                          newOpenRows.splice(newOpenRows.indexOf(event.id), 1);
                        } else {
                          newOpenRows.push(event.id);
                        }
                        setOpenRows(newOpenRows);
                      }}
                    >
                      {openRows.includes(event.id) ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                ) : (
                  <TableCell sx={tableStyles.right}></TableCell>
                )}
              </TableRow>
              {event.participants?.length > 0 ? (
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openRows.includes(event.id)} timeout="auto" unmountOnExit>
                      <Table size="small" sx={{ background: "white" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ background: "white !important" }} />
                            <TableCell sx={{ background: "white !important" }}>Site</TableCell>
                            <TableCell sx={{ background: "white !important" }}>
                              {event.stop.getTime() < new Date().getTime() ? "" : "Projected"}{" "}
                              Energy
                            </TableCell>
                            <TableCell sx={{ background: "white !important" }}>
                              {event.stop.getTime() < new Date().getTime() ? "" : "Projected"}{" "}
                              Revenue
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell colspan="2">
                              <Typography color="secondary">Total</Typography>
                            </TableCell>
                            <TableCell>
                              {event.stop.getTime() < new Date().getTime() ? (
                                <Typography color="secondary">
                                  {calculateTotal(event.participants, "energy")}kWh
                                </Typography>
                              ) : (
                                <Typography color="secondary">
                                  {calculateTotalEnergyRange(event.participants)}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              {event.stop.getTime() < new Date().getTime() ? (
                                <Typography color="secondary">
                                  ${calculateTotal(event.participants, "revenue")}
                                </Typography>
                              ) : (
                                <Typography color="secondary">
                                  {calculateTotalRevenueRange(event.participants, event.program)}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                          {event.participants?.map((participant) => {
                            if (event.stop.getTime() < new Date().getTime()) {
                              return (
                                <TableRow key={participant.name + "-" + participant.site}>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    {participant.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    {participant.site}
                                  </TableCell>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    {participant.energy || 0}kWh ({participant.energy_capacity}kWh){" "}
                                  </TableCell>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    ${participant.revenue}
                                  </TableCell>
                                </TableRow>
                              );
                            } else {
                              return (
                                <TableRow key={participant.name + "-" + participant.site}>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    {participant.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    {participant.site}
                                  </TableCell>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    {calculateEnergyRange(participant).min +
                                      "kWh - " +
                                      calculateEnergyRange(participant).max +
                                      "kWh"}
                                  </TableCell>
                                  <TableCell
                                    sx={{ background: "#F8FAFB !important", color: "grey" }}
                                  >
                                    {"$" +
                                      calculateRevenueRange(participant, event.program).min +
                                      " - $" +
                                      calculateRevenueRange(participant, event.program).max}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              ) : null}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

EventTable.propTypes = {
  entity: PropTypes.string,
  resources: PropTypes.object,
};

export default EventTable;
