/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ClickAwayListener, Box } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useQuery } from "@tanstack/react-query";
import { getSiteEnergyProductionQuery } from "../../../lib/Queries";
import PropTypes from "prop-types";

const DropdownEnergy = ({ site, displayRange }) => {
  const [open, setOpen] = useState(false);
  const [energy, setEnergy] = useState({});

  const { isLoading, error, data, refetch } = useQuery({
    ...getSiteEnergyProductionQuery(
      site.uuid,
      displayRange.start,
      displayRange.stop,
      displayRange.interval,
    ),
  });

  useEffect(() => {
    if (data) {
      setEnergy({ ...data });
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [displayRange]);

  const handleClick = () => {
    setOpen(!open);
  };

  const formatEnergy = (energy) => {
    // TODO if energy null, we display "--", we probably need a global library for this formatting.
    if (energy === null) return "--";

    let unit = "KWh";
    if (energy > 10000) {
      energy = energy / 1000;
      unit = "MWh";
    }
    return (
      new Intl.NumberFormat("en-US").format(energy, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }) +
      " " +
      unit
    );
  };

  return (
    <Box width="100%" display="flex">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <List
          className="select-box"
          sx={{
            width: "100%",
            height: "44px",
            maxWidth: 278,
            position: "absolute",
            backgroundColor: "#fff",
            zIndex: 999,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            onClick={handleClick}
            sx={{
              width: "278px",
              paddingTop: "0px",
              top: "2px !important",
              color: "rgba(255,255,255,.8)",
            }}
          >
            <ListItemText
              key={0}
              primary={
                <>
                  <span className="select-text">Total Production: </span>
                  <span style={{ color: "#78BB39" }}>
                    {isLoading ? "Loading..." : formatEnergy(energy.site_energy)}
                  </span>
                </>
              }
            />
            {open ? (
              <ExpandLess style={{ color: "#000" }} />
            ) : (
              <ExpandMore style={{ color: "#000" }} />
            )}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit className="collapse-root-with-shadow">
            <List component="div" disablePadding>
              {energy.der_groups &&
                energy.der_groups.map((assembly, index) => {
                  return (
                    <ListItemText
                      sx={{ paddingLeft: "15px", paddingTop: "4px", paddingBottom: "4px" }}
                      key={index}
                      primary={
                        <>
                          <span className="select-text">{assembly.group_assemblies}: </span>
                          <span style={{ color: "#78BB39" }}>
                            {error
                              ? "Error..."
                              : isLoading
                                ? "Loading..."
                                : formatEnergy(assembly.energy)}
                          </span>
                        </>
                      }
                    />
                  );
                })}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </Box>
  );
};

DropdownEnergy.propTypes = {
  site: PropTypes.object.isRequired,
  displayRange: PropTypes.object,
  manualRefresh: PropTypes.bool,
};

export default DropdownEnergy;
