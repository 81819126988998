/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AlarmDetail from "./AlarmDetail";
import AlarmSummary from "./AlarmSummary";
import { useTheme } from "@mui/material/styles";
import AlarmTooltipContents from "./AlarmTooltipContents";

const AlarmTimelineTooltip = ({ active, payload, coordinate, getBucketAlarms, timezone }) => {
  const theme = useTheme();

  if (!(active && payload && payload?.length)) return null;

  const styles = {
    container: {
      position: "absolute",
      bottom: ".75em",
      left: `${coordinate.x}px`,
    },
  };

  const bucketAlarms = getBucketAlarms(payload);

  return (
    <Box className="custom-tooltip" style={styles.container}>
      <AlarmTooltipContents alarms={bucketAlarms} timezone={timezone} />
    </Box>
  );
};

AlarmTimelineTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number,
      payload: PropTypes.object,
    }),
  ),
  label: PropTypes.string,
  coordinate: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  getBucketAlarms: PropTypes.func,
  timezone: PropTypes.string,
};

export default AlarmTimelineTooltip;
