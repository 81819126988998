/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Collapsible, { hasKeys } from "./Collapsible";
import PowerSource from "./PowerSource";
import SiteLink from "../Shared/SiteLink";
import { AlarmIndicator } from "../Shared/AlarmIndicator";
import { getCurrentAlarms } from '../../lib/Queries'

/**
 * Sum values from the source of a site
 *
 * @param {Object} sources array
 */
export function sumValues(source, isValid) {
  let powerSum = 0;
  if (isValid) {
    powerSum = source.reduce((sum, { power }) => sum + Math.abs(power), 0);
  }
  return powerSum;
}

export const WrongDataRow = ({ site }) => {
  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }} className="tr-display site-grey">
      <TableCell scope="row" className="site-grey" style={{ paddingLeft: "24px" }}>
        {site}
      </TableCell>
      <TableCell scope="row" className="site-grey" style={{ paddingLeft: "32px" }}>
        --
      </TableCell>
      <TableCell />
      <TableCell scope="row" className="site-grey" style={{ paddingLeft: "35px" }}>
        --
      </TableCell>
      <TableCell>
        <IconButton aria-label="expand row" size="small">
          <KeyboardArrowDownIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const Row = (props) => {
  const [open, setOpen] = useState(false);
  const [alarmDetail, setAlarmDetail] = useState([]);
  const { row } = JSON.parse(JSON.stringify(props));
  const keys = hasKeys(row, ["name", "uptime", "power_data"]);

  // Alarms
  const getAlarms = async (siteName, uuid) => {
    setOpen(!open);
    if (!open) {
      // track that this user viewed the alarms for this site
      if (window.analytics) {
        window.analytics.track("View Site Alarms", { app: "NOC", site: siteName });
      }

      await getCurrentAlarms(uuid)
        .then((data) => {
          setAlarmDetail(data.alarms);
        })
        .catch((err) => {
          //TODO - Replace this axios call with React Query
        });
    }
  };

  if (keys.is_valid) {
    return (
      <React.Fragment>
        <TableRow
          onClick={() => getAlarms(row.name, row.uuid)}
          key={row.name}
          sx={{ "& > *": { borderBottom: "unset" } }}
          className="tr-display"
        >
          <TableCell scope="row" style={{ paddingLeft: "24px" }}>
            <SiteLink site={row} />
          </TableCell>
          <TableCell style={{ paddingLeft: "19px" }}>
            <AlarmIndicator alarms={row.alarms} />
          </TableCell>
          <TableCell className="posgress-td" style={{ verticalAlign: "bottom" }}>
            {<PowerSource powerData={row.power_data} />}
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => getAlarms(row.name, row.uuid)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow role={row.name} key={row.name + "_collapsible"}>
          {<Collapsible open={open} alarms={alarmDetail} name={row.name} />}
        </TableRow>
      </React.Fragment>
    );
  } else if (keys.keys.indexOf("name") === -1) {
    return (
      <>
        <WrongDataRow site={row.name} />
      </>
    );
  }
};

WrongDataRow.propTypes = {
  site: PropTypes.string.isRequired,
};

export default Row;
