/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SortedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell key="name" component="th" style={{ paddingLeft: "24px" }}>
          <TableSortLabel
            active={orderBy === "name"}
            direction={orderBy === "name" ? order : "asc"}
            onClick={createSortHandler("name")}
            IconComponent={KeyboardArrowUpIcon}
          >
            Site
            {orderBy === "name" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell key="qtyAlarms" component="th">
          <TableSortLabel
            active={orderBy === "qtyAlarms"}
            direction={orderBy === "qtyAlarms" ? order : "asc"}
            onClick={createSortHandler("qtyAlarms")}
            IconComponent={KeyboardArrowUpIcon}
          >
            Alarms
            {orderBy === "qtyAlarms" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell key="total_power" width={"51%"} component="th">
          <TableSortLabel
            active={orderBy === "total_power"}
            direction={orderBy === "total_power" ? order : "asc"}
            onClick={createSortHandler("total_power")}
            IconComponent={KeyboardArrowUpIcon}
          >
            Power
            {orderBy === "total_power" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell width={"5%"} />
      </TableRow>
    </TableHead>
  );
};

SortedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default SortedTableHead;
