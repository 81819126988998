/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `#FFFFFF`,
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #E6E6E6",
    fontSize: 17,
    padding: 8,
  },
}));
const GreyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `#4E4E4E`,
    color: "rgba(255, 255, 255, 0.87)",
    border: "1px solid #E6E6E6",
    fontSize: 17,
  },
}));

/**
 * Update power data by adding a 10% value to zero power entries
 * and calculate the new sum of power values.
 *
 * @param {Object} powerData - The power data object to be updated
 * @returns {Object} - An object containing the updated powerData and the new powerSum
 */
export const updatePowerData = (powerData) => {
  const powerDataLength = powerData.source.length;
  let sum = 0;
  for (let i = 0; i < powerDataLength; i++) {
    sum += Math.abs(powerData.source[i].power);
  }

  const powerToAdd = sum * 0.1;
  for (let i = 0; i < powerDataLength; i++) {
    const obj = powerData.source[i];
    obj.unavailable = false;

    if (obj.power === null || obj.power === undefined) {
      obj.displayPower = parseFloat(powerToAdd.toFixed(2));
      obj.unavailable = true;
    } else {
      obj.displayPower = obj.power;
    }
  }

  sum = 0;
  for (let i = 0; i < powerDataLength; i++) {
    sum += Math.abs(powerData.source[i].power);
  }

  return { powerData, sum };
};

const PowerSource = ({ powerData }) => {
  const { powerData: updatedPowerData, sum: powerSum } = updatePowerData(powerData);
  const [isTotalPowerZero, setIsTotalPowerZero] = useState(false);
  const [isPowerAvailable, setIsPowerAvailable] = useState(false);
  const [pipeLoad, setPipeLoad] = useState(0);

  useEffect(() => {
    if (updatedPowerData.total_power === 0 && updatedPowerData.total_power !== null) {
      setIsTotalPowerZero(true);
    } else {
      let calcPipeLoad = parseFloat((updatedPowerData.load / powerSum) * 100).toFixed(2);
      calcPipeLoad = isNaN(calcPipeLoad) ? 20 : parseFloat(calcPipeLoad) + 20;
      setPipeLoad(calcPipeLoad);
    }

    updatedPowerData.source.forEach((power) => {
      if (power.power !== null) {
        setIsPowerAvailable(true);
      }
    });
  }, []);

  return (
    <div style={{ width: "85%" }}>
      {!updatedPowerData.total_power || isNaN(updatedPowerData.load) ? (
        ""
      ) : (
        <GreyTooltip
          title={`Load: ${updatedPowerData.load} ${updatedPowerData.unit}`}
          placement="top"
        >
          <span
            className="pipe"
            role="span"
            style={{ left: `${pipeLoad > 100 ? 100 : pipeLoad}%` }}
          />
        </GreyTooltip>
      )}
      <ProgressBar bsPrefix="progress-size">
        <span
          role="power"
          title={updatedPowerData.total_power}
          aria-label={updatedPowerData.total_power}
          className="total_power"
        >
          {isTotalPowerZero
            ? "0kW"
            : !updatedPowerData.total_power
              ? "--"
              : `${updatedPowerData.total_power}${updatedPowerData.unit}`}
        </span>
        {isTotalPowerZero ? (
          <LightTooltip
            background="FFFFFF"
            key={0}
            title={`Power: 0${updatedPowerData.unit}`}
            placement="top"
          >
            <ProgressBar
              className="power-borders-zero"
              style={{
                "--power-border": "1px solid #F1F1F1",
                background: "#F1F1F1",
              }}
              striped
              bsPrefix
              now={100}
              key={0}
            />
          </LightTooltip>
        ) : !isPowerAvailable ? (
          <LightTooltip background="FFFFFF" key={0} title={`Data Unavailable`} placement="top">
            <ProgressBar
              className="power-borders-zero"
              style={{
                "--power-border": "1px dashed #C0C0C0",
              }}
              striped
              bsPrefix
              now={100}
              key={0}
            />
          </LightTooltip>
        ) : (
          updatedPowerData.source.map((key, index) => (
            <LightTooltip
              background="FFFFFF"
              key={key.name.toLowerCase()}
              title={`${key.unavailable ? `${key.name}: Data Unavailable` : `${key.name}: ${key.power}${updatedPowerData.unit}`}`}
              placement="top"
            >
              <ProgressBar
                key={key.name.toLowerCase()}
                className={
                  key.unavailable
                    ? "power-borders-zero x"
                    : `power-borders ${key.name.toLowerCase()} ${key.power < 0 ? "stripped" : ""}`
                }
                striped
                bsPrefix
                now={(parseFloat(Math.abs(key.power) / powerSum) * 100).toFixed(2)}
              />
            </LightTooltip>
          ))
        )}
      </ProgressBar>
    </div>
  );
};

PowerSource.propTypes = {
  powerData: PropTypes.shape({
    total_power: PropTypes.number,
    load: PropTypes.number,
    unit: PropTypes.string,
    source: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        power: PropTypes.number,
        unavailable: PropTypes.bool,
      }),
    ),
  }).isRequired,
};

export default PowerSource;
